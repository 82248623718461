/*
	Table of Contents
	update on Mon Mar 14 2016 12:38:21 GMT+0200 (FLE Standard Time)

	I.   Generic --------------------------------------
	     01. Reset ------------------------------------
	     02. Base -------------------------------------
	     03. Fonts ------------------------------------
	     04. Helpers ----------------------------------
	         Clear ------------------------------------
	         Notext -----------------------------------
	         Hidden -----------------------------------
	         Alignleft --------------------------------
	         Alignright -------------------------------
	         Disabled ---------------------------------
	II.  Regions --------------------------------------
	     01. Container --------------------------------
	     02. Content ----------------------------------
	     03. Footer -----------------------------------
	     04. Header -----------------------------------
	     05. Main -------------------------------------
	     06. Shell ------------------------------------
	     07. Sidebar ----------------------------------
	     08. Wrapper ----------------------------------
	III. Modules --------------------------------------
	     01. Article ----------------------------------
	     02. Button -----------------------------------
	         Button Block -----------------------------
	     03. Bubble -----------------------------------
	     04. Cols -------------------------------------
	     05. Employees --------------------------------
	     06. Form Elements ----------------------------
	     07. Form -------------------------------------
	     08. Intro ------------------------------------
	     09. List -------------------------------------
	     10. Logo -------------------------------------
	     11. Nav --------------------------------------
	     12. News -------------------------------------
	     13. Product ----------------------------------
	     14. Section ----------------------------------
	     15. Section Products -------------------------
	     16. Section Services -------------------------
	     17. Section Support Intro --------------------
	     18. Section Article --------------------------
	     19. Section Employees ------------------------
	     20. Services ---------------------------------
	     21. Slider -----------------------------------
	     22. Slider Intro -----------------------------
	     23. Support ----------------------------------
	     24. Table ------------------------------------
	     25. Warranty ---------------------------------
	     26. Widget -----------------------------------
 */

.ico-arrow-down { background-image: url(../../img/monacor/sprite.png); background-position: -252px -167px; width: 14px; height: 8px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-arrow-right { background-image: url(../../img/monacor/sprite.png); background-position: -292px -127px; width: 6px; height: 9px; margin-top: -2px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-arrow-up { background-image: url(../../img/monacor/sprite.png); background-position: -252px -127px; width: 36px; height: 36px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-book { background-image: url(../../img/monacor/sprite.png); background-position: -252px 0; width: 46px; height: 36px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-cog { background-image: url(../../img/monacor/sprite.png); background-position: -154px -139px; width: 72px; height: 72px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-direction { background-image: url(../../img/monacor/sprite.png); background-position: -98px -77px; width: 42px; height: 42px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-headset-large { background-image: url(../../img/monacor/sprite.png); background-position: -175px 0; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-headset { background-image: url(../../img/monacor/sprite.png); background-position: -252px -40px; width: 38px; height: 42px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-list { background-image: url(../../img/monacor/sprite.png); background-position: 0 -139px; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-ribbon { background-image: url(../../img/monacor/sprite.png); background-position: 0 0; width: 94px; height: 135px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-warranty-white { background-image: url(../../img/monacor/sprite.png); background-position: -77px -139px; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-warranty { background-image: url(../../img/monacor/sprite.png); background-position: -175px -77px; width: 37px; height: 53px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-wrench-large { background-image: url(../../img/monacor/sprite.png); background-position: -98px 0; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
.ico-wrench { background-image: url(../../img/monacor/sprite.png); background-position: -252px -86px; width: 37px; height: 37px; display: inline-block; vertical-align: middle; font-size: 0; }

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .ico-arrow-down { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -246px -121px; background-size: 292px 210px; width: 14px; height: 8px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-arrow-right { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -286px -38px; background-size: 292px 210px; width: 6px; height: 9px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-book { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -246px 0; background-size: 292px 210px; width: 46px; height: 36px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-cog { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -150px -137px; background-size: 292px 210px; width: 72px; height: 72px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-direction { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -96px -75px; background-size: 292px 210px; width: 42px; height: 42px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-headset-large { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -96px 0; background-size: 292px 210px; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-headset { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -246px -38px; background-size: 292px 210px; width: 38px; height: 42px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-list { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: 0 -137px; background-size: 292px 210px; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-ribbon { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: 0 0; background-size: 292px 210px; width: 94px; height: 135px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-warranty-white { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -75px -137px; background-size: 292px 210px; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-warranty { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -171px -75px; background-size: 292px 210px; width: 37px; height: 53px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-wrench-large { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -171px 0; background-size: 292px 210px; width: 73px; height: 73px; display: inline-block; vertical-align: middle; font-size: 0; }
  .ico-wrench { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -246px -82px; background-size: 292px 210px; width: 37px; height: 37px; display: inline-block; vertical-align: middle; font-size: 0; }
}


/* ------------------------------------------------------------ *\
	Base
\* ------------------------------------------------------------ */



h1 { font-size: 30px; line-height: 1.33; letter-spacing: -0.025em; }
h2 { font-size: 22px; }
h3, .h3 { font-size: 18px; }
.h4 { font-size: 16px; }
.h5 { font-size: 14px; }
.h6 { font-size: 12px; }

h1,
h2,
h3, .h3,
.h4,
.h5,
.h6 { margin-bottom: 0.78em; }

p,
ul,
ol,
dl,
table,
blockquote { margin-bottom: 1.56em; }

h1[class],
h2[class],
h3[class],
.h3[class],
.h4[class],
.h5[class],
.h6[class],
h1:last-child,
h2:last-child,
h3:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
p:last-child,
ul:last-child,
ol:last-child,
dl:last-child,
table:last-child,
blockquote:last-child { margin-bottom: 0; }

@media (max-width: 767px) {
  body { font-size: 14px; line-height: 1.43; }
  h1 { font-size: 18px; }
  h2 { font-size: 20px; }
  h3, .h3 { font-size: 16px; }
}

/* ------------------------------------------------------------ *\
	Helpers
\* ------------------------------------------------------------ */

/*  Clear  */

.clear:after { content: ''; line-height: 0; display: table; clear: both; }


/*  Notext  */

.notext { white-space: nowrap; text-indent: 100%; text-shadow: none; overflow: hidden; }
.notext-old { font-size: 0; line-height: 0; text-indent: -4000px; }


/*  Hidden  */

[hidden],
.hidden { display: none; }


/*  Alignleft  */

.alignleft { float: left; }


/*  Alignright  */

.alignright { float: right; }


/*  Disabled  */

[disabled],
.disabled { cursor: default; }

/* ------------------------------------------------------------ *\
	II.  Regions
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Container
\* ------------------------------------------------------------ */

.container {}

/* ------------------------------------------------------------ *\
	Content
\* ------------------------------------------------------------ */

.content {}

/* ------------------------------------------------------------ *\
	Footer
\* ------------------------------------------------------------ */

.footer {}

/* ------------------------------------------------------------ *\
	Header
\* ------------------------------------------------------------ */

.header {}

/* ------------------------------------------------------------ *\
	Main
\* ------------------------------------------------------------ */

.main {}

/* ------------------------------------------------------------ *\
	Shell
\* ------------------------------------------------------------ */

.shell { max-width: 1280px; margin: auto; padding: 0 24px; }

/* ------------------------------------------------------------ *\
	Sidebar
\* ------------------------------------------------------------ */

.sidebar {}

/* ------------------------------------------------------------ *\
	Wrapper
\* ------------------------------------------------------------ */

.wrapper { position: relative; min-height: 100%; overflow: hidden; }

/* ------------------------------------------------------------ *\
	III. Modules
\* ------------------------------------------------------------ */

/* ------------------------------------------------------------ *\
	Article
\* ------------------------------------------------------------ */

.article p + h3 { margin-top: 62px; }

@media (max-width: 767px) {
  .article p + h3 { margin-top: 24px; }
}

/* ------------------------------------------------------------ *\
	Button
\* ------------------------------------------------------------ */

.btn { display: inline-block; vertical-align: middle; -webkit-appearance: none; -moz-appearance: none; appearance: none; }


/*  Button Block  */
.btn-block { display: block; padding-left: 0; padding-right: 0; }

/* ------------------------------------------------------------ *\
	Bubble
\* ------------------------------------------------------------ */

.text-bubble { text-align: right; padding-right: 38px; margin-top: 7px; }
.text-bubble-inner { position: relative; display: inline-block; width: 370px; padding: 14px 22px 12px; border: 4px solid #ed1c24; text-align: left; font-size: 14px; font-weight: bold; }

.text-bubble-inner:before,
.text-bubble-inner:after { position: absolute; bottom: -4px; left: 100%; border: 20px solid transparent; content: ''; border-left-color: #ed1c24; border-bottom-color: #ed1c24; }
.text-bubble-inner:after { margin: 0 0 4px -8px; border-left-color: #fff; border-bottom-color: #fff; }

@media (max-width: 767px) {
  .text-bubble-inner { width: auto; }
}

/* ------------------------------------------------------------ *\
	Cols
\* ------------------------------------------------------------ */

.cols { margin: 0 -12px; }
.col { float: left; padding: 0 12px; }
.col-1of2 { width: 50%; }

@media (max-width: 1023px) {
  .cols { margin: 0; }
  .col-1of2 { float: none; width: auto; padding: 0; }
  .col-1of2 ~ .col-1of2 { margin-top: 24px; }
}

/* ------------------------------------------------------------ *\
	Employees
\* ------------------------------------------------------------ */

.employees { list-style: none outside none; margin: 0 -12px; font-size: 0; }

.employee { display: inline-block; vertical-align: top; width: 33.3%; padding: 0 12px; font-size: 14px; line-height: 1.43; }
.employee-inner { box-shadow: 0 2px 8px rgba(0,1,0,.15); }

.employee:nth-child(3) ~ .employee { margin-top: 24px; }

.employee-image { position: relative; height: 160px; background: #d1d3d4 url(images/employee.png) no-repeat center bottom; }

.employee-about { padding: 20px 25px; }
.employee-about p { margin: 0; }
.employee-about p a { word-break: break-all; }
.employee-about p span { display: inline-block; width: 63px; }
.employee-about h3 { margin-bottom: 9px; }
.employee-about .employee-meta { margin-bottom: 13px; text-transform: uppercase; font-weight: normal; }

@media (max-width: 1023px) {
  .employee { width: 50%; }

  .employee:nth-child(2) ~ .employee { margin-top: 24px; }
}

@media (max-width: 767px) {
  .employee { display: block; width: auto; }

  .employee:nth-child(3) ~ .employee,
  .employee:nth-child(2) ~ .employee { margin-top: 0; }

  .employees-outer { margin: 0 -5px; }
  .employees { margin: 0; }
  .employee { padding: 5px; }

  .employee-image {
    background-position: left;
  }

  .employees-outer .bx-wrapper .bx-pager.bx-default-pager { bottom: -35px; }
  .employees-outer .bx-wrapper .bx-pager.bx-default-pager a { background: #d8d8d8; }
  .employees-outer .bx-wrapper .bx-pager.bx-default-pager a.active { background: #888; }
}

/* ------------------------------------------------------------ *\
	Form Elements
\* ------------------------------------------------------------ */

input::-webkit-input-placeholder { color: inherit; opacity: 1; }

input::-moz-placeholder { color: inherit; opacity: 1; }

input:-ms-input-placeholder { color: inherit; opacity: 1; }

input::placeholder { color: inherit; opacity: 1; }
textarea::-webkit-input-placeholder { color: inherit; opacity: 1; }
textarea::-moz-placeholder { color: inherit; opacity: 1; }
textarea:-ms-input-placeholder { color: inherit; opacity: 1; }
textarea::placeholder { color: inherit; opacity: 1; }

input:-webkit-autofill { -webkit-text-fill-color: inherit !important; -webkit-box-shadow: 0 0 0 1000px #fff inset; }

.field {}
.textarea {}
.select {}
.radio {}
.checkbox {}

/* ------------------------------------------------------------ *\
	Form
\* ------------------------------------------------------------ */

.form {}

/* ------------------------------------------------------------ *\
	Intro
\* ------------------------------------------------------------ */

.intro { position: relative; }
.intro .intro-image { position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-position: center center; background-size: cover; background-repeat: no-repeat; }

.intro .intro-content { padding: 40px 0 197px; letter-spacing: -0.025em; font-size: 14px; line-height: 1.43; }

.intro .intro-content-inner { position: relative; color: #fff; width: 352px; padding: 28px 38px 30px; background: #ed1c24; }
.intro .intro-content-inner:after { position: absolute; left: 100%; bottom: 0; border: 20px solid transparent; border-left-color: #ed1c24; border-bottom-color: #ed1c24; content: ''; }

.intro .intro-content .actions { padding-top: 13px; letter-spacing: 0; }
.intro .intro-content .actions a { font-weight: bold; color: #fff; }

.intro .intro-content h1 { color:#fff; font-weight: bold; margin-bottom: 12px; }

@media (max-width: 767px) {
  .intro .intro-content { padding: 193px 0 24px; font-size: 12px; }
  .intro .intro-content-inner { width: 198px; padding: 12px; }
  .intro .intro-content p { display: none; }

  .intro .intro-content h1 { margin-bottom: 2px; margin-top: 0; }
}

/* ------------------------------------------------------------ *\
	Monacor-Intro
\* ------------------------------------------------------------ */
.monacor-intro .monacor-intro-inner { position: relative; height: 480px; }
.monacor-intro .monacor-intro-image { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background-position: 50% 50%; background-size: cover; }
.monacor-intro .monacor-intro-content { position: absolute; bottom: 0; left: -20px; width: 50%; padding: 20px 92px 38px 45px; border-radius: 20px; border-bottom-right-radius: 0; background: #ed1c24; color: #fff; }
.monacor-intro .monacor-intro-title { color: #fff; margin-bottom: 15px; line-height: 1.22; margin-top:0 }
.monacor-intro .monacor-intro-meta { position: absolute; bottom: 0; right: 0; width: 320px; border-top-left-radius: 18px; padding: 18px 40px; background: rgba(0,0,0, .8); color: #fff; }
.monacor-intro .monacor-intro-meta strong { display: block; }
.monacor-intro .monacor-intro-meta span { display: block; }
/* Intro Home */
.monacor-intro-home .monacor-intro-inner { height: 545px; }
.monacor-intro-home .monacor-intro-content { padding-right: 30px; background: #ed1c24; }
@media (max-width: 1023px) {
  .monacor-intro .monacor-intro-image-mobile-center { background-position: right center; }

  .monacor-intro .monacor-intro-content { width: 60%; padding-right: 50px; }
}
@media (max-width: 767px) {
  .monacor-intro .monacor-intro-title { font-size: 18px; }
  .monacor-intro .monacor-intro-inner { height: auto; }
  .monacor-intro .monacor-intro-image { position: relative; height: 0; padding-top: 37%; }
  .monacor-intro .monacor-intro-content { position: static; width: 100%; padding: 23px 15px; border-radius: 0; background: #ed1c24; }
  .monacor-intro .monacor-intro-meta { width: auto; padding: 15px 30px; }
}

/* ------------------------------------------------------------ *\
	List
\* ------------------------------------------------------------ */

[class^="list-"] { list-style: none outside none; }

/* ------------------------------------------------------------ *\
	Logo
\* ------------------------------------------------------------ */

.logo {}

/* ------------------------------------------------------------ *\
	Nav
\* ------------------------------------------------------------ */

.nav {}


/* ------------------------------------------------------------ *\
	Product
\* ------------------------------------------------------------ */

.products { margin: 0 -10px; list-style: none outside none; font-size: 0; }
.product { padding: 10px; display: inline-block; width: 33.3%; vertical-align: top; }

.product a { display: block; width: 380px; max-width: 100%; padding-top: 16px; margin: 0 auto; color: #000; transition: all .2s;
  text-decoration: none; }
.product a:hover { text-decoration: none; color: #ed1c24; }

.product .product-image { height: 260px; margin-bottom: 10px; line-height: 260px; }
.product .product-image img { width: auto; height: auto; max-height: 100%; vertical-align: middle; }

.product .product-content { padding: 0 48px; font-size: 14px; line-height: 1.57; }
.product .product-content h3, .product .product-content .h3 { margin-bottom: 12px; }
.product .product-content p { margin-bottom: 15px; }

.product .actions { font-weight: bold; }
.product .actions span { text-decoration: none; }
.product .actions i { opacity: 0; transition: all .2s; }
.product a:hover .actions i { opacity: 1; }

@media (max-width: 1200px) {
  .product .product-content { padding: 0 20px; }
}

@media (max-width: 1023px) {
  .product .product-content { padding: 0 5px; }

  .product .product-image { height: 160px; line-height: 160px; }
}

@media (max-width: 767px) {
  .products { margin: 0 -24px; }
  .product { position: relative; display: block; width: auto; padding: 0; }
  .product a { position: relative; padding: 25px; width: auto }
  .product a:before { position: absolute; bottom: 0; left: 0; right: 0; height: 110px; background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%); content: ''; }

  .product .product-content { padding: 0; position: relative; }

  .product .product-image { height: auto; line-height: 1; }
}

/* ------------------------------------------------------------ *\
	Section
\* ------------------------------------------------------------ */

.section { padding: 40px 0 48px; }

.section-head { position: relative; padding-bottom: 15px; margin-bottom: 36px; }
.section-head:before,
.section-head:after { position: absolute; bottom: 0; height: 6px; content: ''; }

.section-head:before { left: 0; width: 32%; background: #ed1c24; }
.section-head:after { left: 32%; right: 0; margin-left: 4px; background: #d1d3d4; }

@media (max-width: 767px) {
  .section { padding: 18px 0; }

  .section-head { margin-bottom: 24px; }
  .section-head:before { width: 100%; }
  .section-head:after { display: none; }
}


/* ------------------------------------------------------------ *\
	Section Support Intro
\* ------------------------------------------------------------ */

.section-support-intro { font-size: 14px; }

/* ------------------------------------------------------------ *\
	Section Article
\* ------------------------------------------------------------ */
.section-headline {
  padding-top: 0;
}
.section-article .section-image { position: relative; padding-top: 300px; margin-bottom: 65px; background-position: center center; background-size: cover; background-repeat: no-repeat; }
.section-article .section-image .section-ribbon { position: absolute; top: 100%; left: 50%; margin: -25px 0 0 480px; }

.section-article .section-caption { max-width: 950px; margin: 0 auto; color: #fff; }
.section-article .section-caption h1 { color: #fff; }
.section-article .section-caption-inner { position: relative; width: 408px; max-width: 100%; padding: 35px 48px 40px 0; background: rgba(0,0,0,.8); }
.section-article .section-caption-inner:before { position: absolute; top: 0; bottom: 0; right: 100%; width: 100vw; background: rgba(0,0,0,.8); content: ''; }

.section-article .article { max-width: 950px; margin: 0 auto; }

.section-article .cols { margin: 0 -30px; }
.section-article .col { padding: 0 30px; }
.section-article .article h3, .section-article .article .h3 { margin-bottom: 17px; font-size: 16px; }

@media (max-width: 1200px) {
  .section-article .section-image .section-ribbon { display: none; }
}

@media (max-width: 767px) {
  .section-article .section-image { padding-top: 100px; margin-bottom: 24px; }
  .section-article .section-caption-inner { padding: 20px 20px 20px 0; }

  .shell {
    padding: 0 13px;
  }

  .section-article .shell .shell {
    padding: 0;
  }
}

/* ------------------------------------------------------------ *\
	Section Employees
\* ------------------------------------------------------------ */
.section-employees { position: relative; }
.section-employees .shell { position: relative; }
.section-employees:before { position: absolute; bottom: 0; left: 0; right: 0; height: 110px; background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%); content: ''; }

@media (max-width: 767px) {
  .section-employees { position: relative; padding-bottom: 70px; }
  .section-employees .shell { position: relative; }
}

/* ------------------------------------------------------------ *\
	Services
\* ------------------------------------------------------------ */

.services { list-style: none outside none; margin: 42px -12px 0; font-size: 0; }
.service { display: inline-block; width: 25%; padding: 0 12px; vertical-align: top; font-size: 14px; line-height: 1.5; }
.service-inner { border: 2px solid #fff; }

.service .service-head { border-bottom: 2px solid #fff; text-align: center; }
.service .service-head .h4 { padding: 11px 0; margin: 0; color: #fff; }

.service .service-content { overflow: hidden; padding: 27px 26px 25px; }
.service .service-ico { float: left; width: 60px; }
.service .service-entry { overflow: hidden; }
.service .service-entry p { margin: 0; }
.service .service-entry a { color: #fff; word-break: break-all; }
.service .service-entry a:hover { color: #fff; text-decoration: underline }

@media (max-width: 1200px) {
  .service { width: 50%; }
  .service:nth-child(2) ~ .service { margin-top: 24px; }
  .service .service-content { overflow: hidden; padding: 27px 26px 25px; }
  .service .service-entry { margin-left: 70px; }
}

@media (max-width: 767px) {
  .service { display: block; width: auto; }
  .service ~ .service { margin-top: 24px; }
}

/* ------------------------------------------------------------ *\
	Slider
\* ------------------------------------------------------------ */

.slider .slides { list-style: none outside none; padding: 0 }

@media (max-width: 767px) {
  .slider .h1 {
    hyphens: auto;
  }
}

/* ------------------------------------------------------------ *\
	Slider Intro
\* ------------------------------------------------------------ */

.slider-intro .slide { position: relative; }


.slider-intro .bx-wrapper .bx-pager { padding-top: 0; bottom: 26px; font-size: 0; }
.slider-intro .bx-wrapper .bx-pager.bx-default-pager a { width: 16px; height: 16px; border: 2px solid #fff; border-radius: 50%; margin: 0 12px; background: transparent; }
.slider-intro .bx-wrapper .bx-pager.bx-default-pager a.active { background: #fff; }

@media (max-width: 767px) {
  .slider-intro .bx-wrapper .bx-pager.bx-default-pager a { width: 10px; height: 10px; margin: 0 5px; }
  .slider-intro .bx-wrapper .bx-pager { bottom: 12px; }
}

/* ------------------------------------------------------------ *\
	Support
\* ------------------------------------------------------------ */

.support { position: relative; padding-top: 45px; margin-bottom: 5px; overflow: hidden; }
.support .h2 { padding-left: 100px;
  line-height: 2; }

.support-inner { display: table; width: 100%; }

.support-content,
.support-image,
.support-contact { display: table-cell; padding: 27px 20px 22px; vertical-align: top; }

.support-image { width: 292px; border: solid #fff; border-width: 0 3px; min-height: 267px; background-position: center center; background-size: cover; background-repeat: no-repeat; }

.support-content { position: relative; padding-left: 100px; padding-right: 50px; background: #e6e6e6; }

.support-contact { position: relative; width: 264px; background: #ed1c24; text-align: center; color: #fff; font-size: 14px; line-height: 1.57; }

.support-holder { position: relative; }

.support-holder:before { position: absolute; top: 0; bottom: 0; left: 100%; width: 100vw; background: #ed1c24; content: ''; }
.support-holder:after { position: absolute; top: 0; bottom: 0; right: 100%; width: 100vw; background: #e6e6e6; content: ''; }

.support-contact h3,
.support-contact .h3,
.support-contact p { text-align: left; }

.support-contact { padding-right: 0; }
.support-contact i { margin-bottom: 12px; }
.support-contact h3, .support-contact .h3 { margin-bottom: 12px; color: #fff; }
.support-contact p { margin: 0; }
.support-contact p span { display: inline-block; width: 70px; }
.support-contact p a { word-break: break-all; }

.support-contact a { color: #fff; }
.support-contact a:hover { color: #fff; }
.support-upload-icon { margin-bottom: 15px; }

@media (max-width: 1200px) {
  .support .h2 { padding-left: 0; }
  .support-content { padding-left: 0; }
}

@media (max-width: 1023px) {
  .support-content,
  .support-image,
  .support-contact,
  .support-inner { display: block; }

  .support-image { width: auto; min-height: 400px; margin: 0 -24px; border: 0 none; background-position: left 50% top -30px; }

  .support-contact,
  .support-content { width: auto; padding: 24px; margin: 0 -24px; }

  .support-contact h3,
  .support-contact .h3,
  .support-contact p { text-align: center; }

  .support-holder:before,
  .support-holder:after { display: none; }
}

@media (max-width: 767px) {
  .support { padding-top: 30px; margin-bottom: 0; }
  .support-image { width: auto; height: auto; padding-top: 44%; margin: 0 -24px; border: 0 none; min-height: 0; background-position: left 50% top -30px; }

  .support-inner { position: relative; }

  .support-contact i { display: none; }

  .support-contact { padding: 16px 24px; }
  .support-contact h3,
  .support-contact .h3,
  .support-contact p { text-align: left; }

  .support .h2 { position: relative; padding-right: 28px;  font-size: 16px; }
  .support .h2:after { position: absolute; top: 0; bottom: 0; right: 0; width: 14px; height: 8px; margin: auto; background-image: url(../../img/monacor/sprite.png); background-position: -252px -167px; content: ''; }
  .support.expanded .h2:after { -webkit-transform: rotate(180deg); -ms-transform: rotate(180deg); transform: rotate(180deg); }

  .support-content { position: absolute; top: 0; left: 0; right: 0; z-index: 2; display: none; }

  .support.expanded .support-content { display: block; }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .support .h2:after { background-image: url(/design/img/monacor/sprite.@2x.png); background-position: -246px -121px; background-size: 292px 210px; }
}

/* ------------------------------------------------------------ *\
	Table
\* ------------------------------------------------------------ */

.table {}

/* ------------------------------------------------------------ *\
	Warranty
\* ------------------------------------------------------------ */

.warranty { overflow: hidden; padding: 16px 30px 16px 29px; margin-top: 6px; background: #fff; color: #ed1c24; font-weight: bold; }
.warranty i { float: left; margin-right: 28px; }
.warranty p { overflow: hidden; padding-top: 5px; }

@media (max-width: 767px) {
  .warranty { position: relative; padding: 16px 16px 16px 86px; font-size: 15px; }
  .warranty i { float: none; position: absolute; top: 0; bottom: 0; left: 25px; margin: auto; }
}

/* ------------------------------------------------------------ *\
	Widget
\* ------------------------------------------------------------ */

.widgets { list-style: none outside none; }
