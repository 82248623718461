.news-detail .fce-header {
  padding: 0 24px;
}


.newsletter {
  position: relative;

  .about {
    padding-top: 0;
    padding-bottom: 25px;
  }
  .form {
    .input {
      margin-bottom: 10px;
    }
  }
}

.homepage .fce-pagelibrary_newslisting {
  padding-top: 40px;
}

/* ------------------------------------------------------------ *\
	News
\* ------------------------------------------------------------ */
.news {
  list-style: none outside none;
  margin: 0 -12px;
}

.news-headline {
  padding-top: 40px;
}

.news-date {
  display: block;
  padding-bottom: 25px;
}

.news-item {
  display: inline-block;
  vertical-align: top;
  width: 33.3%;
  padding: 0 12px;
  margin-bottom: 25px;
}

.news-item a {
  color: #000;
  display: block;
  box-shadow: 0 4px 16px rgba(0, 1, 0, 0.2);
  text-decoration: none;
}

.news-item a:hover {
  color: #D54B42;
  text-decoration: none;
}

.news-item-image {
  padding-top: 63%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.news-item-content {
  padding: 19px 25px;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.01em;
}

.news-item-content .h5 {
  margin-bottom: 2px;
  letter-spacing: 0;
  font-weight: bold;
  line-height: 1.4;
}

.news-item-content .news-item-meta {
  margin-bottom: 4px;
  letter-spacing: 0;
  font-weight: normal;
}

@media (max-width: 1023px) {
  .news-item-content {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .news-item {
    display: block;
    width: auto;
  }
  .news-item ~ .news-item {
    margin-top: 24px;
  }
  .news-item-content {
    font-size: 13px;
  }
}

.news-item a {
  color: #000;
  display: block;
  box-shadow: 0 4px 16px rgba(0, 1, 0, .2);
  text-decoration: none
}

.news-item a:hover {
  color: #D54B42;
  text-decoration: none;
}

.news-item-content {
  padding: 19px 25px;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: -0.01em;
}
.news-item-content .h5 {
  margin-bottom: 2px;
  letter-spacing: 0;
  font-weight: bold;
  line-height: 1.4;
}

.news-item-meta {
  margin-bottom: 4px;
  letter-spacing: 0;
  font-weight: normal;
}

@media (max-width: 767px) {
  .news-item {
    display: block;
    width: auto;
  }
  .news-item-content {
    font-size: 13px;
  }
}



.news-article {
  font-size: 15px;
  header {
    padding-bottom: 15px;
  }
}

.news-article-text {
  img {
    width: 100% !important;
    height: auto !important;
  }

  column-count: 2;
  column-gap: 25px;

  @media (max-width: $screen-xs-max) {
    column-count: 1;
  }
}

.news-backlink {
  padding-top: 25px;

  a {
    color: $brand-primary;
  }
}

.news-single {
  margin: 0;
  padding-top: 40px;

  @media (max-width: $screen-xs-max) {
    .section-title {
      font-weight: bold;
    }
  }

.article {
  .news-img-wrap {
    padding-top: 25px;
    width: auto;
    max-width: 100%;
    margin: 0;
    float: none;
    background: transparent;

    iframe {
      width: 100% !important;
    }
  }
  .news-img-wrap .viewer a {
    float: none;
    padding: 0;
    border: none;
  }

  .image {

    .viewer {
      text-align: center;

      img {
        margin: 0 auto;
        display: inline-block;

        @media (max-width: $screen-xs-max) {
          width: 100%;
        }
      }

      .item {
        padding: 0px;
        a {
          border: none;
          padding: 0;
        }
      }

      .carousel-indicators {
        bottom: -60px;
        li {
          border-color: #DEDEDE;
          background: #DEDEDE;
        }
        .active {
          background: #8D8D8D;
        }

        @media (max-width: $screen-sm-max) {
          bottom: 8px;
        }

        @media (min-width: $screen-sm-min) {
          display: none;
        }
      }

    }

    .images {
      padding-top: 15px;
      @include clearfix();
    }
    .badges {
      position: relative;
      padding-top: 10px;
    }

  }

  .thumbnails {
    position: relative;

    .thumbnail {
      float: left;
      cursor: pointer;
      border-color: #333;
      background: #000;
      padding: 5px;
      display: block;
      margin: 0;
      height: 80px;
      width: 33.333%;

      &:last-of-type {
        margin-right: 0;
      }

      img {
        max-height: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%)
      }

      .glyphicon-plus {
        margin-top: 21px;
        margin-left: 22px;
      }
    }
    .active {
      border-color: $brand-primary;
    }
  }

}
}

