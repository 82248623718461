
.contactform-close {
  margin-top: -25px;
  margin-right: -25px;
  background: #ED1B24;
}


.contact-header {
  a.btn {
    text-transform: none;
    text-decoration: none;
  }
}


.contact-table, .contact-header {
  padding: 25px 25px !important;
  @media (max-width: $screen-xs-max) {
    padding: 25px 15px !important;
  }
}
.contact-table > .row {
  margin-bottom: 25px;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 0;
    margin-top: 25px;
    border-top: 1px solid #eee;
    padding-top: 25px;
  }
}