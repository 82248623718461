.ico-location {
  background-image: url('../../img/monacor/icon-location.png');
  background-size: 100% 100%;
  width: 30px;
  height: 36px;
  overflow: hidden;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
}


/**
 * ------------------------------------------------------------
 * Landingpage
 * ------------------------------------------------------------
 */
.security-landingpage {

  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;


  .section-body {
    margin-bottom: 4em;
  }

  @media (max-width: $screen-xs-max) {
    .section-body {
      margin-bottom: 2em;
    }
  }

  .bubble {
    position: relative;
    color: rgb(255, 255, 255);
    width: 352px;
    padding: 28px 38px 30px;
    background: rgb(237, 28, 36);
    margin-left: 1em;
    margin-bottom: 3em;

    .bubble-title,
    .bubble-text {
      color: #fff;
    }

    .bubble-title {
      margin: 0;
      line-height: 1.2;
      font-size: 25px;
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    .bubble-text {
      line-height: 1.2;
      font-size: 90%;
    }

    @media (max-width: $screen-xs-max) {
      margin-bottom: 40px;
      margin-left: 0;
      width: 300px;
      .bubble-title {
        font-size: 24px;
      }
    }

    &:after {
      position: absolute;
      left: 100%;
      bottom: 0;
      border: 20px solid rgba(0, 0, 0, 0);
      border-left-color: rgb(237, 28, 36);
      border-bottom-color: rgb(237, 28, 36);
      content: '';
    }
  }

  .security-sector {
    background: #fff;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.5);
    margin-bottom: 30px;

    .security-sector-link {
      display: block;
      text-decoration: none !important;
    }

    .sector-figure {
      max-height: 380px;
      overflow: hidden;
      img {
        min-width: 100%;
      }
    }

    .sector-title {
      font-size: 20px;
      margin-top: 0;
      margin-bottom: 15px;
    }
    .sector-text {
      margin-bottom: 15px;
    }

    .sector-content {
      padding: 30px;
    }

    .sector-more {
      text-decoration: none;
      font-weight: bold;
    }

    .sector-content, .sector-more, .sector-text, .sector-title {
      color: rgb(0, 0, 0);
    }

    &:hover, &:focus {
      .sector-content, .sector-more, .sector-text, .sector-title {
        color: rgb(237, 28, 36);
      }
    }

    &:hover {
      .sector-more:after {
        display: inline-block;
        font-family: 'Glyphicons Halflings';
        font-style: normal;
        font-weight: bold;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: " \e258";
        font-size: 75%;
        padding-left: 5px;
      }
    }
  }

}

/**
 * ------------------------------------------------------------
 * Footer
 * ------------------------------------------------------------
 */
.security-footer {
  padding: 30px 0;

  background: rgb(237, 28, 36);
  color: #fff;


  .security-footer-title {
    color: #fff;
  }

  .security-footer-button {
    display: inline-block;
    margin: 3em 0;
    background: #fff;
    color: rgb(237, 28, 36);
    border-radius: 4px;
    padding: 10px 15px;
    text-decoration: none;
    box-shadow: 0 0 15px -1px rgba(0,0,0,.5);
    line-height: 1;
    transform: scale(1);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 15px -5px rgba(0,0,0,.5);
    }
    span {
      display: inline-block;
      padding: 12px 0;
      fon-size: 20px;
      color: #000;
      font-weight: bold;
    }
  }

  .security-footer-box {
    border: 1px solid #fff;
    padding: 15px;
    @include clearfix();
    i {
      float: left;
      margin-right: 23px;
      background-position: -392px -155px;
      width: 37px;
      height: 42px;
    }
    p {
      margin-left: 60px;
    }
  }

  @media (min-width: 1024px) {
    .security-footer-title {
      width: 40%;
    }
  }


  @media (max-width: $screen-xs-max) {
    .security-footer-button {
      margin: 3em auto;
    }
    .security-footer-button-container {
      text-align: center;
    }
  }
}


/**
 * ------------------------------------------------------------
 * Sticky CTA
 * ------------------------------------------------------------
 */

.security-cta {
  top: 326px;
  position: fixed;
  right: 0;
  z-index: 500;
  width: 32px;

  .security-cta-wrap {
    position: relative;
    min-height: 230px;
  }

  a {
    display: block;
    position: absolute;
    top: -16px;
    right: 16px;
    z-index: 501;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    transform: rotate(270deg);
    transform-origin: right center;
    text-decoration: none;
    width: 212px;
    padding: 5px 0;
    background-color: rgb(237, 27, 36);
    color: #fff;
  }

  .ico-location {
    width: 20px;
    height: 22px;
    margin-right: 5px;
    background-image: url('/design/img/monacor/icon-location-w.png');
  }
}

/**
 * ------------------------------------------------------------
 * Sticky Nav
 * ------------------------------------------------------------
 */
.security-nav {
  background: #fff;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;


  .section-nav-buttons {
    @include clearfix();
    display: flex;
  }

  .nav-button {
    flex-grow: 1;
    flex-shrink: 1;

    width: 40%;
    min-height: 55px;
    padding: 10px;
    font-weight: bold;
    text-decoration: none;
    display: block;
    float: left;
    text-align: center;
    background: #fff;
    color: #000;

    span {
      font-size: 100%;
      display: inline-block;
    }

    i {
      background-repeat: no-repeat;
      background-position: 0 0;
      display: inline-block;
      vertical-align: middle;
    }

    i.icon-security-video {
      height: 35px;
      width: 40px;
      background-image: url('/design/img/monacor/icon-sicherheit-video.png');
      margin-right: 5px;
    }
    i.icon-security-alarm {
      height: 35px;
      width: 40px;
      background-image: url('/design/img/monacor/icon-sicherheit-alarm.png');
      margin-right: 5px;
    }
    i.icon-refresh {
      height: 25px;
      width: 35px;
      background-image: url('/design/img/monacor/icon-refresh.png');
    }

    @media (max-width: $screen-xs-max) {
      i {
        margin: 0;
      }
      span {
        font-size: 80%;
        display: block;
      }
    }

  }

  .nav-button:hover {
    color: rgb(237, 28, 36);

    i.icon-security-video,
    i.icon-security-alarm {
      background-position: 0 -35px;
    }
  }

  .nav-button:active, .nav-button.active {
    background: rgb(237, 28, 36);
    color: #fff;

    i.icon-security-video,
    i.icon-security-alarm {
      background-position: 0 -70px;
    }
  }

  .nav-button-switch {
    width: 20%;
    font-size: 12px;
    line-height: 1;
    background: rgb(191, 191, 191);
    color: #fff;


    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    span {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;

      i {
        display: block;
      }
      small {
        display: block;
        font-weight: normal;
        font-size: 11px;
        margin-right: 10px;
        max-width: 120px;
      }
    }


    &:hover {
      color: #fff;
      background: rgb(206, 206, 206);
    }


    @media (max-width: $screen-xs-max) {
      span {
        display: block;
        i {
          display: none;
        }
        small {
          margin-right: 0;
          max-width: initial;
        }
      }
    }
  }
}

/**
 * ------------------------------------------------------------
 * Sticky
 * ------------------------------------------------------------
 */
body.security-nav-sticky {
  .security-nav {
    position: fixed;
    z-index: 999;
    top: 49px;
    left: 0;
    right: 0;
  }
}

/**
 * ------------------------------------------------------------
 * Intro w/ Images
 * ------------------------------------------------------------
 */
.security-about {

  .security-about-text {
    margin-bottom: 25px;
    line-height: 1.5;
  }

  .security-about-images {

    .security-image-box {
      padding-bottom: 25px;
    }

    .security-image {
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }
    .security-image:before {
      content: "";
      display: block;
      padding-top: 64%;
    }
  }
}

/**
 * ------------------------------------------------------------
 * Overlay Map
 * ------------------------------------------------------------
 */

.security-products {
  margin-bottom: 15px;
  max-height: 66px;
  overflow: hidden;

  .security-products-header {
    cursor: pointer;
    background: rgb(237, 28, 36);
    color: #fff;
    line-height: 1;
    padding: 15px;
    min-height: 48px;

    .security-products-series {
      color: rgb(255, 255, 255);
      margin: 0;
      font-size: 17px;
      font-weight: normal;
    }
    .security-products-toggle {
      text-decoration: none;
      float: right;
      display: block;
      font-size: 20px;
      width: 30px;
      height: 30px;
      color: rgb(255, 255, 255);
      text-align: center;
      margin-top: -4px;

      .security-toggle {
        display: inline-block;
        background-image: url('/design/img/monacor/security-sprite.png');
        background-position: 0 -54px;
        height: 8px;
        width: 14px;
      }
    }
  }

  &.active {
    max-height: 10000px;

    .security-products-toggle .security-toggle {
        background-position: 0 -40px;
    }
  }

  .security-products-content {
    background: rgb(242, 242, 242);
    color: #000;

    .security-products-map {
      background: rgb(205, 205, 205);
      color: #000;
      position: relative;

      .security-map {
        position: relative;
        z-index: 1;
        margin-top: 30px;

        > img {
          max-width: 100%;
          min-width: 100%;
        }

        .hotspot {
          z-index: 20;
          display: block;
          margin: 10px;
          position: absolute;
          font-size: 1rem;

          &.active {
            z-index: 25;
            .hotspot-dot {
              display: none;
            }
            .hotspot-content {
              display: block;
            }
          }
        }

        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.5);
          }
          100% {
            transform: scale(1);
          }
        }

        @media (max-width: $screen-xs-max) {
          .hotspot {
            margin: 0px;
          }

          @keyframes pulse {
            0% {
              transform: scale(0.5);
            }
            50% {
              transform: scale(1);
            }
            100% {
              transform: scale(0.5);
            }
          }
        }

        .hotspot-dot {
          display: block;
          cursor: pointer;
          width: 15px;
          height: 15px;
          background: rgb(237, 28, 36);
          box-shadow: 0 0 0 2px rgb(255, 255, 255);
          border-radius: 50%;
          animation: pulse 1s infinite;
          transform: scale(1);
        }

        .hotspot-content {
          margin-left: -50%;
          display: none;
          min-width: 200px;
          max-width: 280px;
          background: #fff;
          border: 0px solid rgb(237, 28, 36);
          box-shadow: 0 0 10px 2px rgba(0,0,0,0.4);
          padding: 10px;
          font-size: 13px;
          text-decoration: none;
          @include clearfix();
          span {
            display: block;
          }
          img {
            max-width: 100%;
            min-width: initial;
            max-height: 100%;
            min-height: initial;
          }
          .hotspot-image {
            float: left;
            width: 25%;
            max-height: 100px;
            text-align: center;
          }
          .hotspot-label {
            color: #000;
            margin-left: 30%;
            width: 65%;
          }
        }

      }
    }


    .security-products-about {

      padding: 15px 0;

      .security-products-text {
        float: left;
        line-height: 1.5;

        li {
          margin-bottom: 1em;
        }

        .security-products-headline {
            font-size: 18px;
            font-weight: bold;
          color: #000;
          margin-bottom: 2em;
        }
      }

      .security-products-links {
        float: right;

        .security-products-title {
          font-size: 18px;
          font-weight: bold;
          color: #000;
          margin-bottom: 1em;
        }
        .security-products-disclaimer {
          font-size: 90%;
          margin-bottom: 1em;
        }
        .security-products-list {
          margin-bottom: 1em;

          .product-link {
            display: flex;
            background: #fff;
            margin-bottom: 5px;
            padding: 10px;
            min-height: 70px;
          }

          .product-link-image {
            display: block;
            overflow: hidden;
            width: 20%;
            text-align: center;
            img {
              max-height: 50px;
            }
          }

          .product-link-label {
            display: block;
            width: 75%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-left: 10px;
          }

          .product-link-chevron {
            display: flex;
            width: 5%;
            text-align: right;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: flex-end;

            .chevron {
              display: inline-block;
              background-image: url('/design/img/monacor/security-sprite.png');
              height: 15px;
              width: 12px;
            }
          }

          @media (max-width: $screen-sm-max) {
            .product-link-image {
              width: 15%;
            }
            .product-link-label {
              width: 80%;
            }
            .product-chevron{
              width: 5%;
            }
          }


        }
        .security-products-btn {
          display: inline-block;
          margin: 1em 0;
          background: rgb(237, 28, 36);
          color: #fff;
          border-radius: 4px;
          padding: 15px 15px;
          text-decoration: none;
          font-size: 16px;
          line-height: 1;

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;


          @include clearfix();

          span {
            display: block;
            margin-right: 20px;
            text-align: left;
          }

          span.chevron {
            display: inline-block;
            text-align: right;
            background-image: url('/design/img/monacor/security-sprite.png');
            background-position: 0 -20px;
            height: 13px;
            width: 12px;
            margin-right: 0px;
          }
        }
      }

    }

  }

}