.category-header {
  height: 280px;
  background-repeat: repeat-x;
  background-position: bottom left;
}
.category-header-image {
  position: relative;
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 580px;
  height: 280px;

  @media (max-width: $screen-xs-max) {
    background-position: center center;
    background-size: cover;
  }
}
.category-header-slim {
  height: 150px;
  .category-header-image {
    height: 150px;
    background-size: 300px;

    @media (max-width: $screen-xs-max) {
      background-position: center center;
      background-size: cover;
    }
  }
}
.category-header-caption {
  position: absolute;
  bottom: 32px;
  left: 25px;
  margin: 0;
  font-weight: bold;
  color: #fff;
}


#category {


  .section-article {
    padding-top: 0;
  }
  .products {
    padding-left: 0;
  }

  .product {
    margin-bottom: 35px;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      width: 50%;
    }

    a {
      box-shadow: 0 4px 16px rgba(0,1,0,.2);
      padding: 20px;
      padding-top: 0;
    }

    .product-image {
      height: 260px;
      line-height: 260px;
      margin-bottom: 10px;
    }

    .product-content {
      padding: 0 5px 25px 5px;
      position: relative;

      .actions {
        position: absolute;
        bottom: 3px;
      }
    }
  }


  .section-subcats {
      .product-image img {
         max-height: 65%;
      }
  }

}


