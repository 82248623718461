
.meta {
  color: #aaa
}

.visible-mobile {
  display: none;
}
.visible-mobile-inline {
  display: none;
}
.visible-mobile-inline-block {
  display: none;
}


@media (max-width: $grid-float-breakpoint-max) {
  .visible-mobile {
    display: block;
  }
  .visible-mobile-inline {
    display: inline;
  }
  .visible-mobile-inline-block {
    display: inline-block;
  }
  .hidden-mobile {
    display: none;
  }
}

.modal-close {
  background: $primary;
  color: #fff;
  float: right;
  height: 37px;
  width: 37px;
  line-height: 30px;
  border: 0;
  margin-top: -15px;
  margin-right: -15px;
}

.modal .content {
  h2 {
    margin-bottom: 35px;
  }
  hr {
    margin-top: 30px;
    margin-left: -40px;
    margin-right: -40px;
  }
}

.crumbs {
  z-index: 600;
  position: relative;
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 15px;
  }
}

.breadcrumb {
  background: transparent;
  padding: 0;
  margin: 0;
}
.breadcrumb>li {
  line-height: 18px;
}
.breadcrumb>li+li:before {
  font-family: 'Glyphicons Halflings';
  content: "\e258";
  padding: 0 8px 0 3px;
  color: #000;
  position: relative;
  top: 0px;
  font-size: 11px;
}
.breadcrumb a, .breadcrumb span {
  display: inline-block;
  vertical-align: text-bottom;
}
.breadcrumb a {
  color: #000;
}
.breadcrumb>.active {
  color: #aaa;
}



.block {
  margin-bottom: 13px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 25px;
  }
}

.disclaimer {
  margin-top: 15px;
  @include clearfix();

  .star {
    float: left;
    width: 13px;
  }
  .text {
    font-size: 12px;
    margin-left: 13px;
  }
}

.banner {
  max-height: 480px;
  overflow: hidden;
  position: relative;
  img {
    min-width: 100%;
    max-width: 100%;
  }
  .watermark {
    top: 0;
    z-index: 1;
    position: absolute;
  }
  .caption {
    z-index: 1;
    position: absolute;
    top: 40%;
    left: 5%;
    font-size: 35px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;

    @media (max-width: $screen-xs-max) {
      font-size: 18px;
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      font-size: 25px;
    }
  }
}

.banner-products {
  max-height: 375px;

  .caption {
    top: auto;
    bottom: 8%;
    left: 3%;
  }
}

.banner-category {
  .caption {
    top: auto;
    bottom: 8%;
    left: 3%;
  }
}



.corner {
  display: block;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 60px;
  width: 60px;
  background: linear-gradient(135deg, rgba(255,228,0,1) 49%,rgba(250,223,0,0) 50%,rgba(0,0,0,0) 99%);
  .corner-text {
    transform: rotate(-45deg);
    color: #000;
    font-weight: bold;
    font-size: 12px;
  }
}
