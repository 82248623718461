/* ------------------------------------------------------------ *\
	Produktbereiche
\* ------------------------------------------------------------ */

.section-products {
  position: relative;
  padding-top: 0px;

  .as-text {
    line-height: 1.5;
    font-size: 14px;
  }
}

.section-products:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 110px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(245, 245, 245, 1) 100%);
  content: '';
}

.section-products .shell {
  position: relative;
}

@media (max-width: 767px) {
  .section-products {
    padding-bottom: 0;
  }
  .section-products:before {
    display: none;
  }
}


/* ------------------------------------------------------------ *\
	Section Services
\* ------------------------------------------------------------ */

.section-services {
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}

.section-services h2,
.section-services .h2 {
  color: #fff;
  margin-bottom: 16px;
}

.section-services:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(233, 19, 27, .9);
  content: '';
}

.section-services .shell {
  position: relative;
}

.section-services .link-top {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 767px) {
  .section-services {
    padding-bottom: 36px;
  }
}