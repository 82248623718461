#mainnav .nav > li {
  > a[href*="webshop"],
  > a[href*="webshop"]:hover {
    background: rgb(237, 27, 36) !important;
    color: rgb(255, 255, 255) !important;
  }
}

#searchbar {
  border-top: 1px solid rgb(237, 27, 36);
}

// mini navigation (>break only)

#mininav {
  min-height: 50px;

  margin: 0;
  margin-right: -1px;

  display: none;
  @media (min-width: $grid-float-breakpoint) {
    display: block;
  }

  .item {
    background: #BFBFBF;
    color: #fff;
    padding: 4px 20px;
    border-left: 1px solid #fff;
    text-align: center;
    float: left;
    display: inline-block;
    a { color: #fff; }
  }

  .item.lang .dropdown-menu {
    margin-right: -1px;
    margin-top: -20px;
    a { color: #000; text-decoration: none; }
  }


}

// main navigation


.favorites-empty .badge {
  display: none;
}


#mainnav {
  margin-top: 0px;
  margin-bottom: 0px;
  min-height: 25px;
  line-height: 20px;
  border: 0;


  .navbar-toggle {
    background: transparent;
    margin: 0;
    font-size: 25px;
    padding: 15px;
    padding-bottom: 18px;
    border: 0;
    color: #000;
  }

  .menu-close {
    padding: 0 1px;
  }

  .nav>li>a {
    padding: 15px 20px;
    font-size: 15px;
    font-weight: 700;
    color: $black;
    border: 0;
    text-decoration: none;
  }

  .nav>li>a:hover,
  .nav>li>a:focus,
  .nav>li.active>a {
    color: $primary !important;
    background: transparent;
  }

  .nav>li.search>a {
    overflow: hidden;
    line-height: 17px;
    font-size: 17px;
  }


  .toggle-favorites .badge {
    position: absolute;
    z-index: 10;
    right: 6px;
    top: 2px;
    background: $primary;
    font-weight: normal;
    padding: 4px 6px;
  }

  .dropdown-menu {
    padding: 0;
    border: 0;
    z-index: 9999;
  }
  .dropdown-menu>li {
    border-bottom: 1px solid $black20;
  }
  .dropdown-menu>li:last-of-type {
    border-bottom: 0;
  }
  .dropdown-menu>li>a {
    text-decoration: none;
    font-size: 16px;
    padding: 12px 20px;
    color: #000;
    font-weight: 700;
  }
  .dropdown-menu>li>a:hover {
    background: #fff;
    color: $primary !important;
  }

  span.caret {
    margin-left: 0;
    margin-top: 3px;
    float: right;
  }
  .navbar-collapse {
    border-top: 0;
  }
  .navbar-nav {
    margin: 0 -12px;
  }

  .navbar-header {
    .toggle-search, .toggle-favorites {
      display: none;
    }
  }

  .dropdown-toogle {
    display: none;
  }

  @media (max-width: $grid-float-breakpoint-max) {

    .dropdown-toogle {
      position: absolute;
      right: 0;
      top: 0;
      outline: 0;
      width: 48px;
      height: 50px;
      float: right;
      background: #fff;
      border: 0;
      padding: 0;
      border-left: 1px solid rgb(170, 170, 170);
      border-bottom: 1px solid rgb(170, 170, 170);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      .caret {
        float: none;
      }
    }
    .open .dropdown-toogle {
      width: 49px;
      color: #fff;
      background-color: rgb(237, 27, 36);
      border-left: 1px solid #fff;
      border-bottom: 1px solid #fff;
      .caret {
        transform: rotate(180deg);
        margin-top: -3px;
      }
    }
    .nav .open > a {
      color: #fff;
      background-color: rgb(237, 27, 36);
      border-bottom: 1px solid #fff;
    }

    .toggle-favorites .badge {
      top: 5px;
    }

    .caret {
      display: block !important;
    }

    .search, .merkliste {
      display: none !important;
    }

    .navbar-header {
      @include box-shadow(0 5px 8px -1px rgba(0,0,0,.2));

      .toggle-search, .toggle-favorites {
        display: block;
        position: relative;
        float: right;
        display: block;
        padding: 15px;
      }

      .toggle-favorites {
        margin-right: 4px;
      }

      .toggle-search {
        color: #000;
        font-size: 20px;
        margin-right: 3px;
      }
    }

    .nav>li {
      border-bottom: 1px solid $black20;
    }
    .nav>li.black20bg {
      border-bottom-color: #fff;
    }

    .nav>li>a {
      padding-left: 25px;
    }
    .dropdown-menu {
      margin-left: 25px;
      border-left: 1px solid rgb(170, 170, 170);
    }
    li.visible-xs {
      display: block !important;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    margin-top: 20px;
    .navbar-nav {
      float: right !important;
    }
    li.dropdown:hover {
      .dropdown-menu {
        display: block;
      }
    }
  }

}

// Helper classes

.visible-menu-open {
  display: none;
}
.visible-menu-closed {
  display: block;
}
body.menu-visible {
  .visible-menu-open {
    display: block;
  }
  .visible-menu-closed {
    display: none;
  }
  #mainnav .navbar-toggle {
    background: $primary;
    color: #fff;
  }
}

body.search-visible {
  @media (max-width: $grid-float-breakpoint-max) {
    #mainnav .navbar-header .toggle-search {
      color: $primary;
    }
  }
}


.navbar-brand {
  transition: padding 400ms;
  padding: 0;
  padding-left: 63px;
  .navbar-logo {
    transform-origin: bottom left;
    transition: transform 200ms;
    height: 75px;
    transform: scale(1);
  }
}

// desktop
@media (min-width: $grid-float-breakpoint) {
  .navbar-brand {
    margin-top: -46px;
  }

  // desktop when scrolling down
  body.scrolling {
    padding-top: 125px;

    .navbar-brand {
      margin-top: -33px;
      .navbar-logo {
        transform: scale(0.5);
      }
    }

    &.security-nav-sticky {
      padding-top: 180px;
    }

    #mininav {
      display: none;
    }
    #mainnav {
      margin-top: 0;
    }
    #app {
      position: relative;
      max-width: 100%;
      #header {
        background: transparent;
        position: fixed !important;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1010;
        .navbar {
          background: #fff;
        }
      }
    }
    @media (min-width: 1298px) {
      #app #header {
        width: 1280px;
      }
    }
  }
}

// smaller screeens
@media (max-width: $grid-float-breakpoint-max) {

  .navbar-brand {
    margin-top: -33px;
    .navbar-logo {
      transform: scale(0.5);
    }
  }

  body {
    padding-top: 54px;
    &.security-nav-sticky {
      padding-top: 109px;
    }
  }
  #app {
    position: relative;
    max-width: 100%;

    #header {
      background: transparent;
      position: fixed !important;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1010;

      .navbar {
        background: #fff;
      }
    }
  }
}

// smaller screeens
@media (max-width: $screen-xs-max) {
  .navbar-brand {
    margin-top: -30px;
    padding-left: 27px;
  }
}

