@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .aspect-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@import "imagegrid";
@import "contactform";
@import "cookiebar";
@import "downloads";
@import "forms";
@import "lightbox";
@import "merkliste";
@import "news";
@import "printorder";
@import "product-images";
@import "search-brand-box";
@import "searchbar";
@import "textbausteine";
@import "typo3";
@import "heise-shariff";
@import "filter";