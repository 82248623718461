body {
  background: #DEDEDE;
  font-size: 14px;
  line-height: 1.45;
}

.element-dce_dceuid81 {
  .panel-title a {
    text-decoration: none;
    font-weight: bold;
  }
  .panel-body {
    background: #f2f2f2;
  }
}

h1, h2, h3,
.h1, .h2, .h3 {
  font-weight: 700;
  font-size: 25px;
  margin-bottom: 15px;
  margin-top: 0;
}

h3, .h3 {
  font-size: 20px;
}

a:focus {
  outline: thin dotted;
  outline: 0;
  outline-offset: 0;
}


.teal { color: $primary; }
.black { color: $black; }
.black80 { color: $black80; }
.black60 { color: $black60; }
.black40 { color: $black40; }
.black20 { color: $black20; }


.tealbg { background-color: $primary; }
.blackbg { background-color: $black; }
.black80bg { background-color: $black80; }
.black60bg { background-color: $black60; }
.black40bg { background-color: $black40; }
.black20bg { background-color: $black20; }

.hpad {
  padding: 0 25px;
  @media (max-width: $screen-xs-max) {
    padding: 0 13px;
  }
}

.block-container {
  padding: 25px;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
  }
}

.block-fade {
  background: #fff url('img/bottom-fade-grey.jpg') bottom center repeat-x;
}

.block-grey {
  background-color: #F5F5F5;
}

.headline {
  .head {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
    margin-top: 0px;
  }
  .line {
    margin: 0 -5px;
    > div {
      padding-left: 2.5px;
      padding-right: 2.5px;
    }
    div { height: 5px }
    .line-primary div { background: $primary; }
    .line-secondary div { background: $gray; }
  }

  @media (max-width: $screen-xs-max) {
    .head { font-size: 17px; }
    .line div { height: 3px; }
    .line-secondary { display: none; }
  }
}

.wrapper {
  padding: 0 25px;
  @media (max-width: $screen-xs-max) {
    padding: 0 13px;
  }
}

.btn {
  border-width: 2px;
  border-radius: 4px;
  font-size: 14px;
}
.btn-sm {
  font-size: 12px;
}
.btn-xs {
  font-size: 11px;
}

.btn-primary,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:active:focus {
  background: $primary;
  border-color: $primary;
  color: #fff;
  box-shadow: none;
}

.btn-primary:hover {
  background: transparent;
  border-color: $primary;
  color: $primary;
}

.btn-default,
.btn-default:focus,
.btn-default:active,
.btn-default:active:focus {
  background: #ccc;
  border-color: #ccc;
  color: #000;
  box-shadow: none;
}

.btn-default:hover {
  background: transparent;
  border-color: #ccc;
  color: #000;
}

.fce-textmedia {
  padding-bottom: 45px;
}

a {
  text-decoration: underline;

  .img-idle {
    display: inline-block;
  }
  .img-over {
    display: none;
  }
}
a:hover {
  .img-idle {
    display: none;
  }
  .img-over {
    display: inline-block;
  }
}

.fce-header {
  h1,h2,h3,h4,h5,h6 {
    padding-top: 0;
    padding-bottom: 15px;
    margin-bottom: 36px;
    position: relative;
    &:before,
    &:after { position: absolute; bottom: 0; height: 6px; content: ''; }
    &:before { left: 0; width: 32%; background: #ed1c24; }
    &:after { left: 32%; right: 0; margin-left: 4px; background: #d1d3d4; }
  }
}


.caret {
  top: 1px;
  border: none;
  width: 16px;
  height: 16px;
  font-size: 12px;
  position: relative;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &:before {
    content: "\e259";
  }
}


.button {
  background: #eee;
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  position: absolute;
  z-index: 10;
  border-radius: 50%;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.1;
  top: 25px;
  right: 25px;
}
.button-inline {
  position: static;
  top: auto;
  right: auto;

}
.button span {
  display: table-cell;
  vertical-align: middle;
}
.button-black {
  background-color: #000;
  color: #fff;
}
.button-teal {
  background-color: $primary;
  color: #fff;
}
.button-orange {
  background-color: #FF4434;
  color: #fff;
}
.button {
  width: 100px;
  height: 100px;

  @media (max-width: $screen-xs-max) {
    font-size: 10px;
    top: 13px;
    right: 13px;
    width: 60px;
    height: 60px;
  }
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    font-size: 12px;
    width: 80px;
    height: 80px;
  }
}

a.download {
  color: #000;
  text-decoration: none;
  &:before {
    background: $primary none repeat scroll 0 0;
    color: #fff;
    content: "\e025";
    display: inline-block;
    font-family: "Glyphicons Halflings";
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    text-align: center;
    width: 30px;
    text-indent: -2px;
  }
}


/* ------------------------------------------------------------ *\
	Intro

.intro .intro-inner {
  position: relative;
  height: 480px;
}
.intro .intro-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
  background-size: cover;
}

.intro .intro-content {
  position: absolute;
  bottom: 0;
  left: -20px;
  width: 50%;
  padding: 20px 92px 38px 45px;
  border-radius: 20px;
  border-bottom-right-radius: 0;
  background: rgb(237, 27, 36);
  color: #fff;
}

.intro .intro-title {
  color: #fff;
  margin-bottom: 15px;
  line-height: 1.22;
  margin-top: 0
}

.intro .intro-meta {
  display: none;
}

.intro .intro-meta strong {
  display: block;
}

.intro .intro-meta span {
  display: block;
}

.intro-home .intro-inner {
  height: 545px;
}

.intro-home .intro-content {
  padding-right: 30px;
  background: rgb(237, 27, 36);
}

@media (max-width: 1023px) {
  .intro .intro-image-mobile-center {
    background-position: right center;
  }

  .intro .intro-content {
    width: 60%;
    padding-right: 50px;
  }
}

@media (max-width: 767px) {
  .intro .intro-title {
    font-size: 18px;
  }

  .intro .intro-inner {
    height: auto;
  }

  .intro .intro-image {
    position: relative;
    height: 0;
    padding-top: 37%;
  }

  .intro .intro-content {
    position: static;
    width: 100%;
    padding: 23px 15px;
    border-radius: 0;
    background: rgb(237, 27, 36);
  }

  .intro .intro-meta {
    width: auto;
    padding: 15px 30px;
  }
}

.intro-home .shell-no-gutter {
  padding: 0 !important;
}


.section-text {
  margin-bottom: 50px;
  padding: 0 24px;
  @media (max-width: $screen-xs-max) {
    padding: 0 12px;
  }
}

.section-text .col.col-2of3 {
  width: 66% !important;
  float: none !important;
}



\* ------------------------------------------------------------ */


.element-login {
  margin: 40px 22px;
  label {
    width: 149px;
  }
  legend {
    padding-top: 30px;
    font-size: 16px;
  }
  input[type="submit"] {
    display: inline-block;
    margin-top: 20px;
    background: rgb(237, 28, 37);
    color: rgb(255, 255, 255);
    padding: 6px 22px;
    border: 0;
    border-radius: 5px;
  }
}



.gridelement {
  .single-product {
    max-width: 288px;
    margin: 0 auto;
  }
}