.flyouts {
  position: fixed;
  top: 220px;
  right: 0;
  z-index: 500;
  width: 32px;

  @media (max-width: $screen-xs-max) {
    top: 110px;
  }


  &.open-career, &.open-contact {
    width: 320px;
  }

  .flyout {
    position: relative;
    min-height: 91px;
  }

  .flyout-handle, .flyout-content {
    background-color: $primary;
  }
  .flyout-handle, .flyout-content,
  .flyout-content .h4, .flyout-content a {
    color: #fff;
  }

  .flyout-handle {
    display: block;
    position: absolute;
    top: -16px;
    right: 16px;
    z-index: 501;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    transform: rotate(270deg);
    transform-origin: right center;
    text-decoration: none;
    width: 90px;
    padding: 6px 0;
  }
  .flyout-content {
    padding: 25px;
    font-size: 14px;
    margin-bottom: 2px;

    .h4 {
      margin-top: 0;
      margin-bottom: 15px;
      margin-right: 25px;
      font-size: 18px;
      font-weight: bold;
    }

    a {
      text-decoration: underline;
    }

  }

  #fly_career {
    .flyout-handle, .flyout-content {
      background-color: #878787
    }
  }



}