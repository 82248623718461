#footer {

  .links {
    padding: 25px;
    background: #d3d4d5;
    color: #000;
    text-align: right;
    a {
      color: #000;
      font-weight: bold;
      text-transform: uppercase;
    }
    a:hover {
      color: $primary;
      text-decoration: none;
    }

    .page-link {
      padding-right: 15px;
    }

    .pages {
      padding-top: 3px;
      display: inline-block;
      float: left;
    }

    .social {
      a {
        padding-left: 10px;
      }
    }
  }
  .contact {
    padding: 25px;
    background: #f3f3f3;
    color: #2e2d2e;
    .brand-info img {
      margin-top: 20px;
      max-width: 210px;
    }

    .contact-button {
      border-radius: 4px;
      background: $primary;
      color: #fff;
      text-align: center;
      margin-bottom: 9px;
      padding: 10px;
      white-space: nowrap;

      a { color: #fff; text-decoration: none; }
    }
  }
  .about {
    padding: 32px 25px;
    font-size: 13px;
    background: #e7e7e7;
    color: #2e2d2e;
    .related a {
      color: #000;
    }
  }
  .border {
    padding: 12px 25px;
    line-height: 1;
    background: $primary;
    color: #fff;
    strong {
      padding-right: 15px;
    }
  }
}

@media (max-width: $screen-xs-max) {
  #footer {
    > div {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }

    .links {
      column-count: 2;
      column-gap: 15px;

      .social {
        float: none;

        a {
          padding-left: 10px;
          padding-right: 0;
        }
      }

      .page-link {
        padding-right: 0;
        padding-bottom: 10px;
        display: block;
        font-size: 9px;
      }
    }

    .address {
      font-size: 11px;
    }

    .contact .brand-info img {
      margin-top: 0;
      min-width: 100%;
      max-width: 100%;
      display: inline-block;
    }
    .contact .brand-image {
      text-align: right;
    }

    .contact .tel {
      margin-top: 40px;
    }
    .contact .contact-button {
      margin-left: 15px;
      margin-right: 15px;
    }
    .about .related {
      margin-top: 15px;
    }
  }

}