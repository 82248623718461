#products {
  @media (max-width: $screen-xs-max) {
    .introsection {
      margin-bottom: 40px;
    }
  }
  .section-article {
    padding-top: 0;
  }
}


.filter {
  padding-top: 50px;
  padding-bottom: 35px;

  @media (max-width: $screen-xs-max) {
    padding-top: 20px;
    padding-bottom: 50px;
  }

  h3, .h3 {
    margin-bottom: 25px;
    font-weight: bold;
  }

  .h4 {
    font-size: 15px;
    font-weight: bold;
  }

  .range-filter {
    margin-bottom: 15px;

    .slider {
      width: 100% !important;
    }
    .slider-handle {
      background-image: none;
      background-color: $primary;
      opacity: 1
    }
    .slider-track {
      background-image: none;
      background-color: #eee;
    }
    .slider-selection {
      background-image: none;
      background-color: #ccc;
    }
    .range-value {
      width: 30%;
      display: inline-block;
    }
    .range-values {
      padding-top: 20px;
      @include clearfix();
      text-align: center;
      line-height: 30px;
    }
    .range-value-from {
      float: left;
    }
    .range-value-to {
      float: right;
    }
  }
}


.checkbox-filter {
  li {
    padding-bottom: 10px;
  }

  label {
    font-weight: normal;
    cursor: pointer;
  }
}


input[type=radio], input[type=checkbox] {
  margin-left: 6px !important;
  + label {
    padding-left: 0;
  }
}

input[type=radio] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 8px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
  position: relative;
  z-index: 10;
}
input[type=checkbox] + label:before {
  content: "";
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align:middle;
  margin-right: 8px;
  color: $brand-primary;
  background-color: #fff;
  border-radius: 0px;
  border: 1px solid #ccc;
  position: relative;
  z-index: 10;
}

input[type=radio]:checked + label:before {
  content: "\2022"; /* Bullet */
  color: $brand-primary;
  font-size:1.8em;
  text-align:center;
  line-height:19px;
}
input[type=checkbox]:checked + label:before {
  content:"\2714"; /* Tick */
  color: $brand-primary;
  text-align:center;
  line-height:21px;
}


.product-blocks-header {
  padding-bottom: 0;

  @media (max-width: $screen-xs-max) {
    margin-top: 0px;
  }
  
  .product-sort {
    text-align: right;
    margin-right: -25px;

    @media (max-width: $screen-xs-max) {
      margin-right: -13px;
    }

    .dropdown-toggle {
      color: #000;
    }

    .dropdown-menu {
      right: 1px;
      left: auto;
    }
  }

  .product-label {
    font-weight: bold;
    margin-left: -1px;
    padding: 8px 0;
    color: #000;
  }
}


.product-blocks-items {
  background: #F8F8F8;
  background: linear-gradient(to bottom, #f8f8f9 0%,#d1d3d4 100%);
  padding: 0;
  padding-top: 30px;

  @media (max-width: $screen-xs-max) {
    padding: 13px;
    padding-top: 0;
  }

  .listitem {
    .item {
      display: block;
      position: relative;
      background: #fff;
      margin-bottom: 5px;
      padding: 15px;
      text-decoration: none;
      color: #000;

      &:hover {
        color: #000;
      }
    }
    .title {
      font-weight: bold;
      color: $brand-primary;

      @media (max-width: $screen-xs-max) {
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    .meta {
      font-size: 13px;
      @media (max-width: $screen-xs-max) {
        font-size: 12px;
      }
    }

    .teaser {
      font-size: 13px;
      @media (max-width: $screen-xs-max) {
        font-size: 12px;
      }
    }
    .price {
      text-align: right;
      @media (max-width: $screen-xs-max) {
        font-size: 12px;
        padding-top: 10px;
      }
    }

    .image {
      height: 65px;
      text-align: center;

      @media (max-width: $screen-xs-max) {
        height: 140px;
      }

      img {
        display: inline-block;
        max-height: 100%;
      }
    }
  }


  .griditem {
    .item {
      min-height: 360px;
      margin-bottom: 25px;
      display: block;
      position: relative;
      background: #fff;
      text-decoration: none;
      @include box-shadow(0px 1px 5px 0px rgba(0, 0, 0, 0.2));

      &.item-mini {
        min-height: 300px;
      }

      @media (max-width: $screen-xs-max) {
        min-height: 310px;
      }

      @media (min-width: $screen-sm-min) {
        transition: transform 150ms ease-out;
        z-index: 1;
      }
      &:hover {
        @media (min-width: $screen-sm-min) {
          transform: scale(1.1);
          z-index: 100;
        }
      }

    }

    .image {
      padding: 35px;
      padding-bottom: 0;
      display: flex;
      align-items: center;

      @media (max-width: $screen-xs-max) {
        padding: 13px;
        padding-bottom: 0;
        height: 135px !important;
      }
    }

    .image img {
      max-width: 100%;
      max-height: 100%;
      height: auto !important;
      width: auto !important;
    }

    .link {
      opacity: 0;

      background: $primary;
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 15px;
      text-align: center;
      text-transform: uppercase;

      transition: opacity 250ms ease-out;
    }


    .details {
      padding: 25px;
      color: #000;
      text-decoration: none;
      min-height: 200px;
      position: relative;

      @media (max-width: $screen-xs-max) {
        padding: 13px;
        min-height: 175px;
      }

      .title {
        font-weight: 700;
        margin: 0;
        @media (max-width: $screen-xs-max) {
          font-size: 14px;
        }
      }

      .meta, .teaser {
        font-size: 13px;
        @media (max-width: $screen-xs-max) {
          font-size: 9px;
        }
      }

      .previous {
        text-decoration: line-through;
        color: #ccc;
      }

      .price {
        position: absolute;
        bottom: 20px;
        @media (max-width: $screen-xs-max) {
          font-size: 11px;
        }
      }

    }

  }

  a:hover {
    text-decoration: none;
  }


  @media (min-width: $screen-sm-min) {
    a:hover .link {
      opacity: 1;
    }
  }

}

