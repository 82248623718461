
// "product" DCE
.single-product {
  position: relative;
  border-radius: 10px;

  .item {
    border: 2px solid $primary;
    display: block;
    background: #fff;
    text-decoration: none;
    border-radius: 10px;
    margin-bottom: 35px;
    overflow: hidden;
    transform: scale(1);

    @media (max-width: $screen-xs-max) {
      margin-bottom: 20px;
    }

    @media (min-width: $screen-sm-min) {
      transition: transform 150ms ease-out;
      z-index: 1;
    }

    &:hover {
      @media (min-width: $screen-sm-min) {
        transform: scale(1.1);
        z-index: 100;
        box-shadow: 0px 7px 8px 0px rgba(0,0,0,0.2)
      }
    }

  }

  .image {
    position: relative;
    padding: 25px;
  }

  .image img {
    max-width: 100%;
    max-height: 100%;
    height: auto !important;
    width: auto !important;
    min-width: 100%;
  }

  .link {
    opacity: 0;
    background: $primary;
    color: #fff;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    padding: 15px;
    text-align: center;
    transition: opacity 250ms ease-out;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }


  .details {
    padding: 0 25px 25px 25px;
    color: #000;
    text-decoration: none;
    min-height: 180px;
    position: relative;
    background: #f9efef;

    .title {
      color: #ed1c25;
      font-weight: 700;
      margin: 0;
      padding-top: 10px;
      font-size: 17px;
      padding-bottom: 3px;
    }


    .meta, .title {
      background: #F1F1F1;
      margin-left: -25px;
      margin-right: -25px;
      padding-left: 25px;
      padding-right: 25px;
    }

    .meta {
      padding-bottom: 10px;
      color: #333;
    }

    .meta, .teaser {
      font-size: 13px;
    }

    .teaser {
      padding-bottom: 20px;
    }

    .previous {
      text-decoration: line-through;
      color: #ccc;
    }

    .price {
      position: absolute;
      bottom: 10px;
    }


    @media (max-width: $screen-xs-max) {
      padding: 13px;


      .meta, .title {
        background: #F1F1F1;
        margin-left: -13px;
        margin-right: -13px;
        padding-left: 13px;
        padding-right: 13px;
      }
      .title {
        font-size: 14px;
      }
      .teaser {
        font-size: 12px;
      }
      .teaser {
        font-size: 12px;
      }
    }
  }

  a:hover {
    text-decoration: none;
  }

  @media (min-width: $screen-sm-min) {
    a:hover .link {
      opacity: 1;
    }
  }
}



.overview {
  padding: 25px 50px;
  @media (max-width: $screen-xs-max) {
    padding: 25px 15px;
  }
  position: relative;

  .social {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 601;

    a {
      display: inline-block;
      padding-bottom: 10px;
    }
  }

  .corner {
    left: 13px;
  }

  .image {
    .viewer {
      text-align: center;
      border: 1px solid #eee;

      img {
        margin: 0 auto;
        display: inline-block;

        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      .item {
        padding: 40px;
        height: 360px;
      }

      .carousel-indicators {
        bottom: -60px;
        li {
          border-color: #DEDEDE;
          background: #DEDEDE;
        }
        .active {
          background: #8D8D8D;
        }

        @media (max-width: $screen-sm-max) {
          bottom: 8px;
        }

        @media (min-width: $screen-sm-min) {
          display: none;
        }
      }

    }

    .images {
      padding-top: 15px;
      @include clearfix();
    }

    .badges {
      position: relative;
      padding-top: 10px;
    }

  }

  .thumbnails {
    position: relative;
    .thumbnail {
      float: left;
      cursor: pointer;
      border-color: #eee;
      background: #fff;
      padding: 5px;
      display: block;

      margin-right: 9px;
      height: 72px;
      width: 72px;

      &:last-of-type {
        margin-right: 0;
      }

      img {
        max-height: 100%;
        position: relative;
        top: 50%;
        transform: translateY(-50%)
      }

      .glyphicon-plus {
        margin-top: 21px;
        margin-left: 22px;
      }
    }
    .active {
      border-color: $brand-primary;
    }
  }

  .title {
    font-weight: 700;
    font-size: 40px;
  }

  .meta {
    margin-bottom: 50px;
  }

  .desc {
    a {
      font-weight: 700;
    }
  }

  .tagline {
    font-weight: 700;
    margin-bottom: 20px;
  }

  .price {
    text-align: right;
    font-size: 17px;
    margin-bottom:15px;
  }

  .buttons {
    text-align: right;

    .btn-inverse {
      border-width: 2px;
    }
  }
}
.about {
  padding-top: 25px;

  .tabs {
    a {
      display: block;
      padding: 14px 0;
      text-align: center;
      font-size: 14px;
      font-weight: bold;
      background: $primary;
      color: #fff;
    }
    a.active {
      background: #f5f5f5;
      color: #000;
    }
    a:hover {
      text-decoration: none;
    }
    a, a:hover, a:active, a:focus {
      outline: 0;
    }

    .row {
      margin-left: -1px;
      margin-right: -1px;
    }
    .row > div {
      padding-left: 1px;
      padding-right: 1px;
    }
  }

  .about-content {

    .about-padding {
      padding: 50px 100px;
      position: relative;

      @media (max-width: $screen-xs-max) {
        padding: 13px 25px;
      }
      @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        padding: 25px 50px;
      }
    }

    .about-panel {
      position: relative;
    }

    .about-panel:before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 110px;
      background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(245,245,245,1) 100%);
      content: '';
    }

    .accordion-tab {
      display: none;
    }

    @media (max-width: $screen-xs-max) {
      .accordion-tab {
        display: block;
        font-size: 17px;
        padding: 16px 13px;
        color: #fff;
        background: $primary;
        text-transform: uppercase;
        text-decoration: none;
        //font-weight: 700;
        cursor: pointer;
        margin-bottom: 1px;

        .caret {
          top: 5px;
        }
      }
      .accordion-tab:hover {
        text-decoration: none;
      }
      .accordion-tab.active {
        //background: #000;
      }
      .about-panel {
        display: none;
      }
      .about-panel.active {
        display: block;
      }
    }
    @media (min-width: $screen-sm-min) {
      .about-panel {
        display: block !important;
      }
    }
  }

  #infos {
    .h3 {
      margin-bottom: 28px;
    }
  }

  #related {
    .more {
      font-weight: bold;
    }

    .product-blocks-items {
      background: transparent;
      padding: 0 !important;
    }
  }

  #techdetails {
    background: #fff;

    .h4 {
      font-size: 17px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 25px;
      margin-top: -5px;
    }

    table {
      width: 100%;

      @media (max-width: $screen-xs-max) {
        margin-left: 0px;
        border-spacing: 0px 0;
        margin-bottom: 25px;
      }
    }

    td, th {
      padding: 10px 25px;
      width: 50%;

      @media (max-width: $screen-xs-max) {
        padding: 8px 15px;
      }
    }

    thead {
      th {
        background: #000000;
        color: #fff;
      }
    }

    tbody {
      td {
        background: #F2F2F2;
      }
      tr:nth-child(odd) td {
        background: #E6E6E6;
      }
    }

    tfoot {
      td {

      }
    }


    ul.downloads {

      li {
        padding-bottom: 10px;
        line-height: 32px;
      }
      img {
        padding-right: 10px;
      }
      a {
        color: #000;
      }
      a:hover {
        text-decoration: none;
      }
    }


  }


}

.needhelp {
  background: #fff;
  padding-bottom: 15px;
  display: none;
  @media (max-width: $screen-xs-max) {
    padding: 13px;
    display: block;
    margin-bottom: 25px;

    img {
      margin: 0 auto;
    }
  }

  .buttons {
    text-align: center;
  }
  .buttons a {
    display: inline-block;
    line-height: 70px;
    height: 70px;
    width: 70px;
    background: $brand-primary;
    color: #fff;
    margin: 0 25px;
    text-align: center;
    border-radius: 50%;
    font-weight: bold;
  }
  .buttons img {
    width: 50px;
    height: 50px;
  }
}


#related:before {
  display: none;
}
#related {
  background: #F8F8F8;
  background: linear-gradient(to bottom,#f8f8f9 0,#d1d3d4 100%);
}



