// haendler.html

.merchants {
  padding-top: 25px;
  padding-bottom: 25px;
}

.merchant-header {
  margin: 0;
}

.merchant-grid {
  display: flex;
  flex-flow: row wrap;
  margin-left: -10px;
  margin-right: -1px;

  .merchant-item {
    background: #fff;
    width: calc(20% - 20px);
    display: block;
    position: relative;
    border: 6px solid $primary;
    margin: 10px;

    @media (max-width: 1200px) {
      width: calc(25% - 20px);
    }

    @media (max-width: 1024px) {
      width: calc(33.333% - 20px);
    }

    @media (max-width: 767px) {
      width: calc(50% - 20px);
    }

    .embed-responsive {
      padding-bottom: 100%;
    }
  }


  .merchant-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  a.merchant-wrap {
    cursor: pointer;
  }
}


.merchant-grid .merchant-wrap {
  padding: 10px;
}
.merchant-grid .merchant-item img {
  transition: none;
  filter: none;
}
.merchant-grid .merchant-item {
  transition: all 300ms ease;
  border: 1px solid #dedede;
}
.merchant-grid .merchant-item:hover {
  transform: scale(1.04);
  border-color: $primary;
}

@media (min-width: 1201px) {
  body[data-type="101"] {
    .merchant-grid .merchant-item {
      width: calc(25% - 20px);
    }
  }
}

