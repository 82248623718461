
.mo-lgrid
{

  background: rgba(0,0,0,0.03);
  padding: 40px 40px;
  margin: 20px 0;
  @media (max-width: 620px) {
    padding: 40px 15px;
  }

  /*
      Headline
  */

  .lgrid-headline {
    text-align: center;
    padding-bottom: 20px;

    > * {
      font-weight: 300;
      font-size: 30px;
      text-transform: uppercase;
    }
  }


  .lgrid-tag-header {
    margin-bottom: 20px;
  }
  .lgrid-tag-name {
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    padding-bottom: 10px;
  }

  /*
      MRP warning
  */

  .lgrid-disclaimer {
    font-size: 75%;
    text-align: center;
    max-width: 80%;
    margin: 20px auto 0;
    color: #888;
  }


  /*
      Flex Grid
  */
  $gap: 10px;

  .lgrid-items {
    display: flex;
    flex-flow: row wrap;

    .lgrid-item {
      margin: $gap/2;
      flex-basis: calc(25% - #{$gap});
      flex-grow: 0;
      flex-shrink: 0;
      background: #fff;
      margin-bottom: $gap * 2;


      @media (max-width: 820px) {
        flex-basis: calc(33.333% - #{$gap});
      }
      @media (max-width: 620px) {
        flex-basis: calc(50% - #{$gap});
      }
    }

  }

  /*
      Item
  */

  .lgrid-wrap {
    text-align: center;

    .lgrid-title {
      padding: 10px;
      font-weight: bold;
      a {
        text-decoration: none;
      }
    }

    .lgrid-teaser {
      font-size: 80%;
      padding: 0 10px 10px;
    }

    /* Image + Centering */
    .lgrid-image {
      width: 100%;
      margin: 0 auto;
      padding-top: 56.7%;
      position: relative;
      overflow: hidden;

      a {
        height: 100%;
      }

      img {
        min-width: 100%;
        width: auto;
      }

      .lgrid-image-aspect {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }

      .lgrid-image-aspect, a {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .lgrid-tags {
    font-size: 12px;
    color: #888;
    padding: 10px;
    padding-top: 0;
    margin-top: -7px;

    .tag {
      cursor: pointer;
    }
    .tag:hover {
      color: rgb(0, 0, 0);
      text-decoration: underline;
    }
  }


}