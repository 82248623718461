
#element-2305, #element-2308 {
  background: #e6e6e6;
  padding-top: 25px;

  .ce-gallery {
    margin-bottom: 0;
  }
  .ce-row, .ce-textpic, .ce-gallery {
    overflow: visible;
  }
  img {
    transition: transform 0.2s ease;
    transform: scale(1);
  }
  a:hover img {
    transform: scale(1.1);
  }

  @media (max-width: 420px) {
    .ce-row {
      width: 100%;
    }
    .ce-column {
      width: 47%;
      float: left;
      min-height: 250px
    }
  }
}


.support-contact-form {
  background: #e6e6e6;
  padding: 15px 0;
  border-top: 3px solid #fff;
}

.support-icon {
  margin-bottom: 10px;
}

.employee-image {
  background-color: rgb(75, 76, 69);
}

.icon, .icon-white {
  background-color: transparent;
  background-position: 0 0;
  background-repeat: no-repeat;
  display: inline-block;
  width: 77px;
  height: 77px;
}

.icon-white {
  background-image: url('/design/img/intl/service-icons-white.png');
}

.icon-chat {
  background-position: 0 0;
}
.icon-cart {
  background-position: -77px 0;
}
.icon-checklist {
  background-position: -153px 0;
}
.icon-book {
  background-position: -230px 0;
}
.icon-parcel {
  background-position: -307px 0;
}
.icon-gear {
  background-position: -385px 0;
}
.icon-headset {
  background-position: -462px 0;
}
.icon-speaker {
  background-position: -539px 0;
}
.icon-transporter{
  background-position: -615px 0;
}
.icon-wrench {
  background-position: -692px 0;
}
.icon-car {
  background-position: -770px 0;
}
.icon-award {
  background-position: -850px 0;
}


.service-ico {
  .icon-white {
    width: 54px;
    height: 54px;
    background-size: 648px 54px;
  }


  .icon-chat {
    background-position: 0 0;
  }
  .icon-cart {
    background-position: -54px 0;
  }
  .icon-checklist {
    background-position: -108px 0;
  }
  .icon-book {
    background-position: -162px 0;
  }
  .icon-parcel {
    background-position: -216px 0;
  }
  .icon-gear {
    background-position: -270px 0;
  }
  .icon-headset {
    background-position: -324px 0;
  }
  .icon-speaker {
    background-position: -378px 0;
  }
  .icon-transporter{
    background-position: -432px 0;
  }
  .icon-wrench {
    background-position: -486px 0;
  }
  .icon-car {
    background-position: -540px 0;
  }
  .icon-award {
    background-position: -594px 0;
  }
}



#support {

  .search {
    padding-bottom: 35px;

    @media (max-width: $screen-xs-max) {
      padding-bottom: 0px;
    }
  }

  .downloads-mobile {
    padding: 25px;
    .h4 {
      margin-bottom: 15px;
    }
    li {
      padding-bottom: 5px;
    }
    img {
      margin-right: 10px;
    }
  }

  .downloads {

    .header {
      .row > div {
        padding-bottom: 10px;
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }
    }

    .model {
      margin-bottom: 25px;

      img {
        height: 22px;
      }

      a {
        color: #000;
        font-size: 13px;
      }

      .row > div {
        padding-bottom: 10px;
        padding-top: 10px;
        background: #F2F2F2;
        white-space: nowrap;
        height: 45px;
        line-height: 22px
      }

      .row:nth-child(odd) > div {
        background: #E6E6E6;
      }

      .row > div:first-of-type {
        padding-left: 25px;
      }

      .model-name {
        background: #000 !important;
        color: #fff;
      }

    }

  }

}