body[class*="szenario-"] {
  .btn.back-steps { display: none !important; }
  .btn.back-scenarios { display: none !important; }
}

.szenario-gastronomie {
  .security-footer,
  .section-head:before,
  .security-products .security-products-content .security-products-about .security-products-links .security-products-btn,
  .security-products .security-products-header,
  .monacor-intro-home .monacor-intro-content,
  .scenario-button  {
    background-color: #b5cda7 !important;
  }
  .scenario-type-name,
  .scenario-items .scenario-title {
    border-color: #b5cda7 !important;
  }
  .scenario-type-name {
    color: #b5cda7 !important;
  }
}
.szenario-einzelhandel {
  .security-footer,
  .section-head:before,
  .security-products .security-products-content .security-products-about .security-products-links .security-products-btn,
  .security-products .security-products-header,
  .monacor-intro-home .monacor-intro-content,
  .scenario-button {
    background-color: #e8a79b !important;
  }
  .scenario-type-name,
  .scenario-items .scenario-title {
    border-color: #e8a79b !important;
  }
  .scenario-type-name {
    color: #e8a79b !important;
  }
}
.szenario-bildung {
  .security-footer,
  .section-head:before,
  .security-products .security-products-content .security-products-about .security-products-links .security-products-btn,
  .security-products .security-products-header,
  .monacor-intro-home .monacor-intro-content,
  .scenario-button  {
    background-color: #ecc49d !important;
  }
  .scenario-type-name,
  .scenario-items .scenario-title {
    border-color: #ecc49d !important;
  }
  .scenario-type-name {
    color: #ecc49d !important;
  }
}
.szenario-sport {
  .security-footer,
  .section-head:before,
  .security-products .security-products-content .security-products-about .security-products-links .security-products-btn,
  .security-products .security-products-header,
  .monacor-intro-home .monacor-intro-content,
  .scenario-button  {
    background-color: #f0e0a9 !important;
  }
  .scenario-type-name,
  .scenario-items .scenario-title {
    border-color: #f0e0a9 !important;
  }
  .scenario-type-name {
    color: #f0e0a9 !important;
  }
}

.szenario-konferenz {
  .security-footer,
  .section-head:before,
  .security-products .security-products-content .security-products-about .security-products-links .security-products-btn,
  .security-products .security-products-header,
  .monacor-intro-home .monacor-intro-content,
  .scenario-button  {
    background-color: #99c3e1 !important;
  }
  .scenario-type-name,
  .scenario-items .scenario-title {
    border-color: #99c3e1 !important;
  }
  .scenario-type-name {
    color: #99c3e1 !important;
  }
}


.productselector {
  padding: 0;
  position: relative;

  .step-introtext {
    padding: 25px;
    max-width: 790px;
    padding-left: 36px;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .productselector-loading {
    position: absolute;
    background: rgb(255, 255, 255);
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .productselector-spinner {
      margin-top: -100px;
    }
  }

  .productselector-type-select {
    margin: 25px 0;
    text-align: center;

    .btn {
      outline: 0;
      box-shadow: none;
    }

    .btn-primary {
      background: rgb(255, 255, 255);
      border-color: rgb(237, 27, 36);
      color: rgb(237, 27, 36);
    }

    .btn-primary:active,
    .btn-primary.active {
      background: rgb(237, 27, 36);
      border-color: rgb(237, 27, 36);
      color: rgb(255, 255, 255);
    }

  }

  .scenarios {
    padding: 25px;
  }

  .scenario-buttons {
    display: block;
    min-height: 110px;

    .scenario-buttons-wrap {
      margin: 0 auto;
      max-width: 1280px;
    }

    ul {
      display: flex;
      flex-flow: row;
    }
    li {
      flex-basis: 20%;
    }
    a {
      display: flex;
      flex-flow: column;
      justify-content: center;
      min-height: 110px;
      padding: 10px;
      text-decoration: none;
      text-align: center;
      color: #fff;
      &:hover {
        background: $brand-primary !important;
      }
    }
    i {
      display: block;
      font-size: 35px;
      height: 45px;
    }
    span {
      display: block;
    }

    @media (max-width: 1024px) {
      ul {

      }
      li {

      }
    }
    @media (max-width: 768px) {
      a {
        min-height: 130px;
      }
    }
    @media (max-width: 767px) {
      ul {
        flex-flow: row wrap;
        justify-content: space-around;
      }
      li {
        width: 50%;
        flex-basis: 50%;
        margin-bottom: 10px;
        padding: 0;
      }
    }
  }

  .make-sticky .scenario-buttons .scenario-buttons-wrap {
    position: fixed;
    top: 46px;
    right: 0;
    left: 0;
    background: rgb(238, 238, 238);
    margin: 0 auto;
    z-index: 10;
    padding-top: 29px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: 0 5px 10px -5px rgba(0,0,0,0.5);
  }

  .scenario-introtext {
    padding-bottom: 35px;
    max-width: 790px;
    padding-left: 11px;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .scenario-type {
    margin-top: 50px;

    .scenario-type-name {
      color: rgb(237, 28, 36);
      border-bottom: 5px solid rgb(255, 255, 255);
      margin: 11px;
      margin-bottom: 20px;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 767px) {
    .scenario-type {
      padding: 0 12px;
    }
  }


  .scenario-back-to-top {
    background: rgb(255, 255, 255);
    color: rgb(237, 27, 36);
    width: 53px;
    height: 30px;
    padding: 3px 10px;
    text-align: center;
    margin: 0 auto;
    margin-top: 24px;
    display: block;
  }


  .scenario-items {
    display: flex;
    flex-flow: row wrap;

    .scenario {
      cursor: pointer;
      background-size: 364px 200px;
      background-repeat: no-repeat;
      background-position: top center;
      @include aspect-ratio(7.4, 10);
      width: calc(25% - 24px);
      height: 265px;
      margin: 12px;
      box-shadow: 0 0 9px 0 #aaa;
    }

    .scenario-content {
      display: block;
    }

    .scenario-title {
      display: block;
      padding: 8px 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      border-top: 3px solid #ed1d25;
      font-weight: bold;
      background: #fff;
      color: #232323;
      font-size: 13px;
      text-align: center;

      height: 90px;

      @media (max-width: 1200px) {
        height: 100px;
      }
    }


    .scenario-info,
    .scenario-size {
      font-weight: normal;
      font-size: 80%;
      display: block;
    }

    .scenario-info {
      color: rgb(170, 170, 170);
      margin-top: 4px;
      @media (max-width: 1200px) {
        margin-top: 2px;
      }
    }
    .scenario-size {
      color: rgb(170, 170, 170);
    }

    a {
      text-decoration: none;
    }
  }

  .steps {
    // padding-bottom: 300px; // tastatur spacing für messe screen
  }

  .step {
    padding: 40px 0;
    position: relative;
    padding-left: 85px;
    overflow: hidden;

    &.active {
      background: #eee;
    }

    &:before { /* line */
      position: absolute;
      z-index: 0;

      display: block;
      content: '';
      width: 1px;
      height: 100%;
      top: 0;
      bottom: 0;

      border: 2px solid #ec212b;
      left: 50px;
    }
    .step-number-check {
      display: none;
    }
  }

  .step:not(.completed) {
    &:before { /* line */
      height: calc(100% - 15px);
    }
    &:after { /* arrow */
      position: absolute;
      z-index: 0;
      color: #ec212b;
      display: block;
      content: '▼';
      bottom: 0;
      left: 41px;
      font-size: 23px;
      width: 20px;
      height: 19px;
      line-height: 1;
    }
  }

  /* completed step */
  .step.completed {
    &:before { /* line */
      height: 100%;
    }
    .step-number-digit {
      display: none;
    }
    .step-number-check {
      display: inline;
    }
  }

  /* first step */
  .step:first-of-type {  /* cover upper line */
    .step-number.hidden-xs:before {
      position: absolute;
      z-index: 0;

      display: block;
      content: '';
      width: 1px;

      top: -43px;
      left: 12px;
      height: 40px;
      border: 2px solid #fff;
    }
  }
  .step.active:first-of-type { /* cover upper line (while active) */
    .step-number.hidden-xs:before {
      border-color: #eee;
    }
  }

  /* final step */
  .step:last-of-type {

    &:after { /* arrow */
      display: none;
    }

    .step-number.hidden-xs:after { /* cover lower line */
      position: absolute;
      z-index: 0;

      display: block;
      content: '';
      width: 1px;

      top: 31px;
      left: 12px;
      bottom: 0;
      border: 2px solid #fff;
      height: 500px;
    }
  }
  .step.active:last-of-type { /* cover lower line (while active) */
    .step-number.hidden-xs:after {
      border-color: #eee;
    }
  }

  .step:last-of-type {
    border-bottom: 0;
  }

  /* Header Number */

  .step-number {
    background: #fff;
    color: #ec212c;
    font-size: 16px;
    border: 3px solid #ec212c;
    border-radius: 50%;
    height: 34px;
    width: 34px;
    line-height: 27px;
    font-weight: bold;
    display: inline-block;
    text-align: center;
    margin-right: 5px;
    position: relative;
    z-index: 100;
  }
  .step.active .step-number {
    background: #ec212c;
    color: #fff;
  }

  /* Header */

  .step-header {

  }
  .step-title {
    font-weight: bold;
    color: #ec212c;
    font-size: 17px;

  }
  .step-number.hidden-xs {
    font-size: 16px;
    margin-left: -50px;
    margin-right: 15px;
    float: left;
  }
  .step-description {
    padding-bottom: 10px;
  }

  /* Container */

  .step-content {
    padding-top: 24px;
    padding-right: 24px;
    padding-left: 16px;
  }

  .step-footer {
    padding-top: 24px;
    padding-left: 16px;
  }

  /* Tabs */

  .step-tabs {
    border-bottom: 0;
    width: 256px;
    float: left;
  }
  .step-tabs > li {
    width: 100%;
  }

  .step-tab-amt {
    margin-left: 5px;
    background: #4c4c4c;

    &.active {
      background: #ec212c;
      color: #fff;
    }
  }
  .step-tabs > li,
  .step-tabs > li:hover,
  .step-tabs > li:active,
  .step-tabs > li:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
  .step-tabs > li > a,
  .step-tabs > li > a:focus,
  .step-tabs > li > a:active {
    margin: 0 0 6px -1px;
    background: #fff;
    color: #000;
    border-color: #d4d4d4;
    border-radius: 6px;
    text-decoration: none;
    padding: 10px;
  }
  .step-tabs > li > a:hover,
  .step-tabs > li.active > a {
    border-color: #ec222d;
    text-decoration: none;
  }

  .step-body .tab-content {
    padding-top: 0;
    margin-left: 270px;

    &:before, &:after {
      content: " ";
      display: table;
    }
  }

  .step-answer.active {
    background: #ec212c;
    border-color: #ec212c;
    color: #fff;
  }

  /* Product Item */

  .product {
    position: relative;
    float: left;
    padding: 10px;
    width: 340px;
    height: 112px;
    margin-right: 16px;
    margin-bottom: 16px;
    background: #fff;
    box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.17);
    border: 1px solid #dcdcdc;

    &:before, &:after {
      content: " ";
      display: table;
    }

    .product-figure {
      float: left;
      height: 100%;
      width: 95px;
      .product-img {
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: center;
        img {
          align-self: center;
          max-height: 100%;
        }
      }
    }

    .product-name {
      margin-left: 110px;
      color: #ec222b;
    }
    .product-desc {
      font-size: 66%;
      margin-left: 110px;
      color: #8e8e8e;
    }
    .product-options {
      margin-left: 120px;
    }

    .amount-text {
      text-align: center;
    }
    .amount {
      display: inline-block;
      width: 50px;
    }

    .product-options {
      height: auto;
      padding: 0;
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;

      .btn {
        padding: 6px 12px;
        border-radius: 0;
        outline: 0;
        box-shadow: none;
      }
      .btn.btn-amount {
        padding: 5px 5px;
        background: #fff;
        border-color: #efefef;
        border-left: 0;
        border-right: 0;
        z-index: 0 !important;
        margin: 0;

        input {
          color: #333;
          background: transparent;
          border: 0;
          box-shadow: none;
          width: 24px;
        }
      }
      .product-amt-decr,
      .product-amt-incr {
        color: #000;
        background-color: #f5f5f5;
        border-color: #efefef;
      }
      .product-open {
        color: #000;
        background: #f5f5f5;
        border-color: #efefef;
      }
      .product-open-label {
        font-size: 12px;
      }
    }

  }

  .cart-modal-handle {
    position: absolute;
    top: 53px;
    right: 124px;
    z-index: 100;
    width: 34px;
    height: 142px;
    transition: top 500ms ease-out;

    .cart-handle-text {
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      transform: rotate(270deg);
      transform-origin: right center;
      text-decoration: none;
      width: 142px;
      padding: 6px 0;
      display: block;
      position: relative;
      height: 32px;
      color: #fff;
      background: #ed1c25;
    }
    .cart-handle-counter {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: #fff;
      color: #000;
      text-align: center;
      line-height: 28px;
      position: absolute;
      top: 134px;
      font-weight: bold;
      font-size: 14px;
      right: -98px;
      border: 2px solid #ed1c25;
    }
  }

  .cart-total-price {
    border-top: 3px double #ec222b;
    display: inline-block;
    margin-right: 15px;
  }

  .cart-modal {
    position: fixed;
    top: 20px;
    right: 0;
    z-index: 9000;
    width: 320px;
    display: none;

    &.active {
      display: block;
    }

    .cart-modal-wrap {
      position: relative;
      background: #fff;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 15px rgba(0,0,0,0.2);
    }
    .cart-items {
      max-height: 74vh;
      overflow: auto;
    }

    span.price {
      font-size: 12px;
      color: #8a8a8a;
    }

    .cart-modal-close {
      padding: 12px;
      .btn {
        border-radius: 0;
        padding: 7px 24px;
      }
    }

    .cart-actions {
      min-height: 30px;
      padding-top: 5px;
    }

    .cart-modal-title {
      background: #ec212b;
      color: #fff;
      padding: 16px;
      margin: 0;
    }

    .cart-empty {
      margin: 12px;
      padding: 15px;
      background: #ffe7e7;
      text-align: center;
    }

    .cart-items {

    }

    .items-step {
      .items-step-title {
        font-weight: bold;
        border-bottom: 1px solid #fff;
        padding: 15px;
        background: #efefef;
      }
      .items-step-body {
        padding: 15px;
        background: #f7f7f7;
      }
      .prod-unmet {
        color: red;
      }
      .amt {
        padding-right: 12px;
      }
    }
  }

  .product-required {

    .product {
      width: 317px;
    }

    .product-required-wrap {
      padding: 25px;
      min-height: 250px;
    }

    .productselector-modal-scroll {
      overflow: auto;
      height: calc(100% - 100px);
    }

    .required-remove {
      background-color: rgb(238, 238, 238) !important;
      border-color: rgb(238, 238, 238) !important;
      color: rgb(63, 58, 41) !important;
      &:hover {
        background-color: rgb(255, 237, 237) !important;
        border-color: rgb(236, 33, 44) !important;
      }
    }
  }
}



.productselector-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  .productselector-modal-wrap {
    position: relative;
    border: 2px solid #ec212b;
    background: #fff;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    width: 94vw;
    height: 88vh;
    margin-top: 10vh;
    max-width: 900px;

    .table {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  .productselector-modal-close {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(rgba(255, 255, 255, 0), white);
    padding: 10px;
    text-align: center;

    .btn {
      border-radius: 0;
      padding: 7px 24px;
      color: #fff;
      background: #ED1B24;
    }
  }
  .carousel-indicators li,
  .carousel-indicators .active {
    margin: 0;
    width: 20px;
    height: 20px;
  }

}
.productselector-modal-bg {
  background: rgba(0,0,0,0.4);
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.product-detailview {

  .tab-content {
    position: relative;
    height: calc(100% - 40px);
    overflow: auto;
    padding: 15px;
    padding-bottom: 45px;
    margin-left: 0;
    transform: translate3d(0,0,0);
  }
  .nav-tabs {
    margin-right: -2px;
  }
  .nav-tabs li {
    width: 50%;
  }
  .nav-tabs li > a {
    text-align: center;
    color: #fff;
    background: #ED1B24;
    border-color: #ED1B24;
    text-decoration: none;
  }
  .nav-tabs li.active > a {
    color: #ED1B24;
    background: #fff;
    border-color: #fff;
    text-decoration: none;
  }
  .carousel-inner .product-figure {
    display: block;
    height: 36vh;

    .product-img {
      display: flex;
      height: 95%;
      justify-content: center;
      flex-flow: column;
      cursor: default;

      img {
        max-height: 100%;
        max-width: 100%;
        align-self: center;
      }
    }
  }
  .carousel-indicators li {
    border: 1px solid #6d6d6d;
  }
  .carousel-indicators .active {
    background-color: #6d6d6d;
  }
  td.spec-name {
    /* text-align: right; */
    font-weight: bold;
  }
}


.productselector-backbtn {
  color: rgb(0, 0, 0) !important;
  background: transparent !important;
  border-color: transparent !important;
}

@media (max-width: 1199px) {
  .productselector-backbtn {
    padding: 11px 15px !important;
    margin-top: 2px;
    font-size: 20px !important;
    span {
      display: none;
    }
    span.visible-xs {
      display: inline-block !important;
    }
  }
}
@media (min-width: 1200px) {
  .productselector-backbtn {
    position: absolute;
    top: 70px;
    right: 25px;
    z-index: 99;
    border-radius: 0 !important;

    &.back-steps {
      background: #fff !important;
    }
    &.back-scenarios {
      right: 190px;
      background: #fff !important;
    }
  }
}

@media (max-width: 1024px) {
  .productselector {
    .scenario-items {
      .scenario {
        width: calc(33.333% - 24px);
        margin: 12px;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {

  .productselector {
    top: 54px;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    padding: 0;
    margin: 0;

    .product-required {
      .product {
        width: 100%;
      }
      .product-required-wrap {
        padding: 8px;
      }
    }


    .steps {
      padding-bottom: 0;
    }

    .productselector-type-select {
      margin: 0;
      padding: 6px 0;
      background: rgb(206, 28, 37);
      .btn {
        padding: 5px 20px;
        line-height: 1.3;
      }
    }

    .productselector-type {
      height: calc(100% - 39px);
      overflow: auto;
      transform: translate3d(0,0,0);
    }

    .scenarios {
      background: #fff;
      padding: 0;
      padding-bottom: 25px;
    }
    .scenario-items {
      justify-content: space-evenly;
      margin: 0 -4px;

      .scenario {
        margin: 6px;
        width: calc(50% - 12px);
      }

      .scenario-info {
        margin-top: 5px;
      }

      .scenario-title {
        padding: 6px;
        height: 89px;
        font-size: 12px;
      }
    }

    .steps {
      width: 800vw;
      transition: margin-left 400ms ease-in-out;
    }

    .step {
      float: left;
      padding: 0;
      margin: 0;

      border: 0;
      height: 100%;
      width: 100vw;
      position: relative;
      background: #fff;

      &.active {
        background: #fff;
      }

      &:before {
        display: none;
      }
    }

    .step.hidden {
      display: block !important;
    }

    .step-tab-amt {
      margin-left: 0;
      margin-right: 15px;
    }

    .step-number {
      display: block;
      position: relative;
      text-align: center;
      margin: 0 auto;
      border-width: 5px;
      border-radius: 50%;
      border-color: #fff;
      color: #fff;
      height: 50px;
      width: 50px;
      font-size: 25px;
      line-height: 38px;
    }
    .step-number-sm {
      cursor: pointer;
      border-width: 2px;
      height: 28px;
      width: 28px;
      font-size: 15px;
      line-height: 24px;
    }
    .step-numbers {
      position: relative;
      height: 30px;
      margin-bottom: 10px;

      .step-line {
        display: block;
        width: 100%;
        border-top: 2px solid #fff;
        height: 1px;
        margin: 23px 0;
      }

      .step-line-cover {
        display: block;
        border-top: 2px solid #ec212c;
        position: absolute;
        z-index: 5;
        width: 50%;
        top: 0;
      }
      .step-line-prev {
        left: 0;
      }
      .step-line-next {
        right: 0;
      }


      .step-number {
        position: absolute;
        z-index: 10;
        background: #ec212c;
        color: #fff;
      }

      .step-number-center {
        left: calc(50% - 25px);
        top: -24px;
      }

      .step-number-prev {
        left: 15px;
        top: -13px;
      }

      .step-number-next {
        right: 15px;
        top: -13px;
      }

    }

    .step-header {
      text-align: center;
      background: #ec212c;
      padding-top: 20px;
      min-height: 150px;
    }

    .step-description {
      text-align: center;
      padding: 0 15px;
      margin-top: 10px;
      color: #fff;
      font-size: 85%;
      line-height: 1.1;
    }

    .step-nav {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      padding: 10px;
      background: #ec212c;
      z-index: 1001;
      min-height: 52px
    }

    .step-nav a {
      color: #fff;
      background: #c31720;
      border-color: #c31720;
    }

    .step-title {
      margin: 0;
      color: #fff;
    }

    .step-content {
      margin: 0;
      padding: 15px;
      overflow: auto;
      overflow-x: hidden;
      height: calc(100vh - 245px);
      transform: translate3d(0,0,0);
    }

    .step[data-mode="buttons"] {
      .step-body {
        text-align: center;
      }
      .list-inline {
        margin-top: -5px;
      }
      .list-inline > li {
        text-align: center;
        display: block;
        padding: 5px 0;

        .btn {
          padding: 9px 10px;
          display: block;
          width: 100%;
          margin: 0 auto;
          border-radius: 0;
        }
        .btn:hover, .btn:active, .btn.active {
          background-color: #ec212b;
          border-color: #ec212b;
          color: #fff;
          outline: 0;
          box-shadow: none;
        }
      }
    }

    .step[data-mode="products"] {
      .step-content {
        padding: 0;
        width: 200vw;
      }
      .step-body {
        position: relative;
        text-align: left;
        width: 200vw;
        transition: margin-left 300ms ease-in-out;
        &.show-tabcontent {
          margin-left: -100vw;
        }
      }

      .tab-content {
        padding: 15px;
        width: 100vw;
        margin-left: 100vw;
        text-align: left;
      }

      .step-tab-close {
        display: inline-block;
        margin-bottom: 10px;
        background: #ccc;
        border-color: #ccc;
        color: #000;
        border-radius: 0;
        height: 40px;
        line-height: 26px;

        &:active, &:focus {
          background: #ec212b;
          border-color: #ec212b;
          color: #fff;
        }
      }

      .step-tabs {
        padding: 15px;
        width: 100vw;
        height: 100%;
        float: left;
        border: 0;
        margin-top: -5px;

        > li {
          float: none;
          display: block;
          margin-bottom: 0;
          border: 0;
          padding: 5px 0;
        }
        > li > a {
          position: relative;
          text-align: left;
          height: 40px;
          border: 0;
          background: #ccc;
          border-color: #ccc;
          color: #000;
          border-radius: 0;
        }
        > li > a:after {
          content: '>';
          display: block;
          position: absolute;
          right: 10px;
          color: #a0a0a0;
          top: 10px;
        }
        > li.active > a {
          background: #ec212b;
          border-color: #ec212b;
          color: #fff;
        }
        > li.active > a:after {
          color: rgba(255,255,255,0.5);
        }

        .step-tab-amt {
          margin-right: 15px;
          margin-top: 2px;
        }
      }
    }

    .product {
      float: none;
      width: 100%;
      background: none;
      box-shadow: none;

      padding: 0;
      padding-bottom: 12px;

      border: 0;
      border-bottom: 1px solid #e0e0e0;

      margin: 0;
      margin-bottom: 12px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .product-options {
        margin-left: 110px;
        bottom: 9px;
      }
    }


    .cart-modal-handle {
      display: none !important;
    }

    .cart-button-wrap {
      margin: 0 70px;
    }

    .cart-modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      width: 100%;
      display: none;

      &.active {
        display: block !important;
      }

      .cart-modal-wrap {
        position: relative;
        border: 2px solid #ec212b;
        background: #fff;
        box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.4);
        margin: 0 auto;

        width: 94vw;
        height: 86vh;
        margin-top: 70px;
      }
      .cart-items {
        max-height: initial;
        overflow: auto;
        height: calc(100% - 49px);
        padding-bottom: 50px;
        transform: translate3d(0,0,0);
      }
      .cart-modal-title {
        padding: 10px;
        padding-top: 8px;
      }

      .cart-modal-close {
        background: transparent;
        position: absolute;
        z-index: 10;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(rgba(255, 255, 255, 0), white);
        padding: 10px;
        text-align: center;

        .btn {
          border-radius: 0;
          padding: 7px 24px;
        }
      }
    }

    .productselector-modal {
      top: 54px;
    }

    .product-detailview {
      .product-detailview-wrap {
        width: 94vw;
        height: 86vh;
        margin-top: 2vh;
      }

      .tab-content {
        margin-left: 0;
      }
    }

  } // end selector
} // end mobile



@media (max-width: 375px) {
  .productselector {

    .scenario-items {
      .scenario-title {
        font-size: 12px;
      }
    }

    .product-required {
      .product-required-wrap {
        padding: 8px;
      }
      .product-required-products {
        padding-bottom: 100px;
      }
    }

    .product .product-desc {
      font-size: 79%;
    }
  }
}

@media (max-width: 340px) {
  .productselector {
    .product .product-desc {
      font-size: 69%;
    }
    .product-open-label {
      display: none;
    }

  }
}

@media (min-width: $screen-sm-min) {
  .productselector {
    .steps {
      margin-left: 0 !important;
    }

    .scenario-items .scenario {
      transform: scale(1);
      transition: transform 200ms ease-in-out;
    }
    .scenario-items .scenario:hover {
      transform: scale(1.05);
    }

    .product:hover {
      border-color: #ec222b;
    }

    .product-required {
      .product {
        min-width: 317px;
      }
      .product-required-wrap {
        text-align: center;
      }
      .product-required-products {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        text-align: left;
        justify-content: space-around;
        align-items: flex-start;
      }
    }

    .step-nav {
      display: none;
    }

    .cart-modal {
      .cart-modal-close {
        background: #fff;
        color: #000;
        .btn {
          border-radius: 0;
          padding: 7px 24px;
        }
      }
    }

  } // end selector
} // end desktop-only

//
//
// body[class*="szenario"], body[class*="konfigurator"] {
//
//   background: rgb(84, 84, 84); // dark-gray for messe-display
//
//
//   .btn.restart-steps {
//     background: rgb(234, 234, 234);
//     border-color: rgb(204, 204, 204);
//     color: rgb(237, 29, 37);
//     box-shadow: none;
//   }
//
//   #mininav .item.contact,
//   #mininav .item.phone,
//   .navbar-logo.old,
//   #marken,
//   #menu,
//   #footer,
//   body.homepage #searchbar,
//   .flyouts {
//     display: none !important;
//   }
//
//   .navbar-logo.new {
//     display: inline-block !important;
//   }
//
//   .navbar-brand > img {
//     transition: none;
//   }
//
//   #mainnav {
//     min-height: 50px;
//   }
//
//   body.homepage.monacor.scrolling {
//     padding-top: 104px !important;
//   }
//
//   #app #header {
//     z-index: 1000;
//   }
//
//   .navbar-brand {
//     cursor: default !important;
//   }
//
//   .productselector-backbtn {
//     margin-top: 9px;
//     margin-right: 13px;
//   }
//
//   .security-footer .security-footer-button {
//     transform: none !important;
//     box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.5);
//     cursor: default;
//   }
//
//   .security-footer .security-footer-button:hover {
//     transform: none !important;
//     box-shadow: 0 0 15px -1px rgba(0, 0, 0, 0.5);
//     cursor: default;
//   }
//
//   .security-products .security-products-content {
//     margin-top: 17px;
//   }
//
//   .security-products .security-products-content .security-products-about .security-products-links .security-products-btn span.chevron
//   {
//     color: rgba(255,255,255,0.5);
//     width: auto;
//     height: auto;
//     background: none;
//     display: inline-block;
//     font: normal normal normal 14px/1 FontAwesome;
//     font-size: inherit;
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     &::before {
//       content: $fa-var-chevron-right;
//     }
//   }
//
//   .security-products .security-products-header .security-products-toggle .security-toggle {
//     color: rgba(255,255,255,0.5);
//     width: auto;
//     height: auto;
//     background: none;
//     display: inline-block;
//     font: normal normal normal 14px/1 FontAwesome;
//     font-size: inherit;
//     text-rendering: auto;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     &::before {
//       content: $fa-var-chevron-up;
//     }
//   }
//   .security-products.active .security-products-header .security-products-toggle .security-toggle {
//     &::before {
//       content: $fa-var-chevron-down;
//     }
//   }
//   @media (min-width: 1200px) {
//     .navbar-header {
//       float: none !important;
//     }
//   }
//
//   @media (max-width: 1199px) {
//     padding-top: 50px; // new logo makes navbar smaller
//
//     .navbar-header {
//       a:not(.navbar-brand), .navbar-toggle {
//         display: none !important;
//       }
//     }
//
//     #mainnav .navbar-header {
//       box-shadow: none !important;
//     }
//   }
// }
//
// @media (max-width: $screen-xs-max) {
//   body[class*="konfigurator"] {
//     overflow: hidden !important;
//     padding-top: 0 !important;
//
//     #header {
//       display: none;
//     }
//   }
// }