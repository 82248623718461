#map_radius {
  padding-right: 0;
}

#haendlersuche {

  .searchform {
    margin-bottom: 20px;

    h2, .h2 {
      font-size: 20px;
      margin-bottom: 25px;
    }

    form {
      margin: 0;
    }

    #map_productcategory {
      @media (max-width: $screen-xs-max) {
        margin-top: 15px;
      }
    }
  }

  .results {
    background: #fff;

    padding: 0px 25px;
    @media (max-width: $screen-xs-max) {
      padding: 0 13px;
    }

    h2, .h2 {
      font-size: 20px;
      margin: 0;
      margin-bottom: 15px;
    }

    .item {
      display: block;
      background: #eee;
      padding: 15px 25px;
      margin-bottom: 20px;
      font-size: 14px;
      min-height: 92px;
      color: #000;

      &:hover {
        background: $primary;
        color: #fff;
        text-decoration: none;
      }

      .pin {
        width: 26px;
        float: left;
      }

      .haendler {
        display: block;
        margin-left: 40px;
      }
    }

  }

  .display {
    margin-top: 25px;

    @include clearfix();
    position: relative;

    .map-container {
      height: 600px;
    }

    .map {
      height: 100%;
      width: 100%;
    }

    .badge {
      font-size: 13px;
      line-height: 15px;
      background: $primary;
      color: #fff;
    }

    .overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 50%;
      background: rgb(238, 238, 238);
      color: #000;

      @media (max-width: $screen-xs-max) {
        position: relative;
        bottom: auto;
        right: auto;
        width: 100%;
        background: #f5f5f5;
      }
    }

    .store {
      padding: 35px;

      .about {
        margin-bottom: 25px;
      }

      .name {
        color: #000;
        margin-top: 0;
        margin-bottom: 20px;
      }

      .address {
        .pin {
          width: 26px;
          float: left;
        }

        address {
          display: block;
          margin-left: 40px;
        }
      }

      .mobile-contact {
        padding: 10px 0;

        a {
          font-size: 14px;
          overflow: hidden;
          text-decoration: none
        }

        a:hover {
          color: $primary;
        }
      }
    }

    @media (max-width: $screen-xs-max) {
      .results, .store {
        padding: 13px;
      }
      .store {
        .btn {
          width: 100%;
          height: 45px;
          line-height: 30px;
        }

        .link-phone {
          margin-bottom: 22px !important;
        }

        .address {
          .pin {
            display: none
          }

          address {
            margin-left: 0
          }
        }
      }
    }
  }

  &.such-layout-1 {

    .display .overlay {
      display: block;
      position: relative;
      height: auto;
      width: 100%;
      background: rgb(238, 238, 238);
      border-bottom: 8px solid #ed1a24;
    }
  }
}


.facherrichter {
  .carousel-indicators .active, .carousel-indicators li {
    margin: 0 7px;
  }

  .embed-responsive {
    border: 2px solid transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 70%;
  }

  .results .item {
    img {
      transition: all 200ms;
    }
    .bild {
      padding: 20px;
    }
  }

  .results .item:hover {
    background: inherit;
    img {
      transform: scale(1.1);
    }
  }

  .display .store .name {
    margin-bottom: 0 !important;
  }
  .display .map-container {
    height: 340px !important;
  }

  .errichter {
    display: flex;
    height: 100%;
    flex-flow: column-reverse;
    justify-content: center;
    align-items: center;

    .bild {
      img {
        width: 100%;
      }
    }
  }

  .logo {
    padding-bottom: 25px;
    img {
      max-height: 100%;
      width: auto;
      height: 100px;
    }
  }
  .attributes {
    margin-bottom: 20px;
  }
  .description {
    margin-bottom: 20px;
  }

  .display .store {
    padding: 25px 35px;
    font-size: 14px;
  }

  .name, .contact {
    font-weight: bold;
  }
}
